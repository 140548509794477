import { Button, Grid, Typography } from '@material-ui/core'
import { useStyles } from './style'
import { useNavigate } from 'react-router-dom'
import { userSlice } from '../../reducers'
import { useDispatch } from 'react-redux'
import React from 'react'

const HomePageHeader = ({ userName, pageDescription, pageState, hasPreviousResults }) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const dispatcher = useDispatch()

  const handleMenuChange = () => {
    dispatcher(userSlice.actions.setMenuLevel(1))
    dispatcher(userSlice.actions.setActiveMenuSection('you'))
  }
  const handleClick = (page) => {
    navigate(`/${page}`)
    handleMenuChange()
  }
  return (
    <Grid item xs={12} md={6}>
      <Typography data-test='appHeader' variant='h1'>
        {' '}
        Hi {userName}!
      </Typography>
      {/* <button onClick={handleDisconnect}>Disconnect</button> */}
      <Typography className={classes.introText}>
        {pageDescription}
      </Typography>
      <div className={classes.btnContainer}>
        {pageState === 'new' && (
          <Button
            onClick={() => handleClick('review')}
            data-test='btn-startReview'
            className={classes.button}
            variant='contained'
            color='secondary'
          >
            Start your review
          </Button>
        )}
        {pageState === 'new-season' && (
          <Button
            onClick={() => handleClick('review')}
            data-test='btn-startNewReview'
            className={classes.button}
            variant='contained'
            color='secondary'
          >
            Start new review
          </Button>
        )}
        {pageState === 'partial' && (
          <Button
            onClick={() => handleClick('review')}
            data-test='btn-finishReview'
            className={classes.button}
            variant='contained'
            color='secondary'
          >
            Finish your review
          </Button>
        )}
        {pageState === 'recurrent' && (
          <Button
            onClick={() => handleClick('dashboard')}
            data-test='btn-viewResults'
            className={classes.button}
            variant='contained'
            color='primary'
          >
            View your results
          </Button>
        )}
        {pageState === 'new-season' && hasPreviousResults && (
          <Button
            onClick={() => handleClick('dashboard')}
            data-test='btn-viewPreviousResults'
            className={classes.button}
            variant='contained'
            color='primary'
          >
            View previous results
          </Button>
        )}
      </div>
    </Grid>
  )
}

export default HomePageHeader
