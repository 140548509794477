import personalSection from './personal_section'
import organisationSection from './organisation_section'
import firstResourcesSection from './resources_1_section'
import secondResourcesSection from './resources_2_section'
import thirdResourcesSection from './resources_3_section'
import fourthResourcesSection from './resources_4_section'
import firstGoalsSection from './goals_1_section'
import secondGoalsSection from './goals_2_section'
import thirdGoalsSection from './goals_3_section'
import fourthGoalsSection from './goals_4_section'
import firstAlignSection from './align_1_section'
import secondAlignSection from './align_2_section'
import firstListenSection from './listen_1_section'
import secondListenSection from './listen_2_section'
import firstInfluenceSection from './influence_1_section'
import secondInfluenceSection from './influence_2_section'
import thirdInfluenceSection from './influence_3_section'
import firstEnableSection from './enable_1_section'
import secondEnableSection from './enable_2_section'
import thirdEnableSection from './enable_3_section'
import fourthEnableSection from './enable_4_section'
import fifthEnableSection from './enable_5_section'
import sixthEnableSection from './enable_6_section'
import firstEngageSection from './engage_1_section'
import secondEngageSection from './engage_2_section'
import thirdEngageSection from './engage_3_section'
import fourthEngageSection from './engage_4_section'
import firstImproveSection from './improve_1_section'
import secondImproveSection from './improve_2_section'
import thirdImproveSection from './improve_3_section'
import firstTechnologySection from './technology_1_section'
import secondTechnologySection from './technology_2_section'
import thirdTechnologySection from './technology_3_section'
import firstDeliverySection from './delivery_1_section'
import secondDeliverySection from './delivery_2_section'
import generalSection from './general_section'
import contentSection from './content_section'
import infrastructureSection from './infrastructure_section'
import finalSection from './final_1'
import firstOrganisationGoalsSection from './organisationgoals_1_section'
import secondOrganisationGoalsSection from './organisationgoals_2_section'
import firstEmployeeGoalsSection from './employeegoals_1_section'
import secondEmployeeGoalsSection from './employeegoals_2_section'
import secondGeneralSection from './general_2_section'
import thirdGeneralSection from './general_3_section'
import challengesFinalSection from './challenges_final_section'
import finalSectionMarketing from './final_2'

const sections = {
  personal: personalSection,
  organisation: organisationSection,
  resources_1: firstResourcesSection,
  resources_2: secondResourcesSection,
  resources_3: thirdResourcesSection,
  resources_4: fourthResourcesSection,
  goals_1: firstGoalsSection,
  goals_2: secondGoalsSection,
  goals_3: thirdGoalsSection,
  goals_4: fourthGoalsSection,
  align_1: firstAlignSection,
  align_2: secondAlignSection,
  listen_1: firstListenSection,
  listen_2: secondListenSection,
  influence_1: firstInfluenceSection,
  influence_2: secondInfluenceSection,
  influence_3: thirdInfluenceSection,
  enable_1: firstEnableSection,
  enable_2: secondEnableSection,
  enable_3: thirdEnableSection,
  enable_4: fourthEnableSection,
  enable_5: fifthEnableSection,
  enable_6: sixthEnableSection,
  engage_1: firstEngageSection,
  engage_2: secondEngageSection,
  engage_3: thirdEngageSection,
  engage_4: fourthEngageSection,
  improve_1: firstImproveSection,
  improve_2: secondImproveSection,
  improve_3: thirdImproveSection,
  technology_1: firstTechnologySection,
  technology_2: secondTechnologySection,
  technology_3: thirdTechnologySection,
  delivery_1: firstDeliverySection,
  delivery_2: secondDeliverySection,
  general: generalSection,
  general_2: secondGeneralSection,
  general_3: thirdGeneralSection,
  content: contentSection,
  infrastructure: infrastructureSection,
  organisationgoals_1: firstOrganisationGoalsSection,
  organisationgoals_2: secondOrganisationGoalsSection,
  employeegoals_1: firstEmployeeGoalsSection,
  employeegoals_2: secondEmployeeGoalsSection,
  final_1: finalSection,
  final_2: finalSectionMarketing,
  challenges_final: challengesFinalSection
}

export default sections
