import React from 'react'
import { useStyles } from './style'
import { Paper, Typography } from '@material-ui/core'

const DashboardBehaviourCard = ({ score, question, average }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.cardRoot}>
      <Typography variant='body1'>{score} out of 9 for</Typography>
      <Typography variant='body1' className={classes.question}>{question}</Typography>
      <Typography variant='body1'>(vs 2021 average of {average})</Typography>
    </Paper>
  )
}

export default DashboardBehaviourCard
