import name from './name'

const selectSliceData = state => state[name]

const selectUserData = state => selectSliceData(state).userData
const selectMenuLevel = state => selectSliceData(state).menuLevel
const selectActiveMenuSection = state => selectSliceData(state).activeMenuSection
const selectFeatureToggles = state => selectSliceData(state).featureToggles
export {
  selectUserData,
  selectMenuLevel,
  selectActiveMenuSection,
  selectFeatureToggles
}
