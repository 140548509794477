import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { useMediaQuery } from '@material-ui/core'
import {
  RegisterHeader,
  RegisterCarousel,
  RegisterForm,
  RegisterFooter,
  VerifyEmail,
  PageAlert
} from '../../components'

import { useStyles } from './style'
import { registerSlice, loginSlice } from '../../reducers'

const Register = () => {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const isRegistered = useSelector(registerSlice.selectors.selectIsRegistered)
  const showRegisterAlert = useSelector(loginSlice.selectors.selectShowRegisterAlert)
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'))
  if (showRegisterAlert) {
    setTimeout(() => {
      dispatcher(loginSlice.actions.setShowRegisterAlert(false))
    }, 7000)
  }

  const LeftSide = () => {
    return (
      <>
        <Grid item xs={12}>
          <RegisterHeader />
        </Grid>
        <Grid item xs={12}>
          {isRegistered
            ? (
              <VerifyEmail />
              )
            : (
              <RegisterForm />
              )}
        </Grid>

        <Grid item xs={12}>
          <RegisterFooter />
        </Grid>
        {showRegisterAlert && (
          <PageAlert text='A verification email was sent to the address provided.' />
        )}
      </>
    )
  }

  const RightSide = () => {
    return (
      <Grid item xs={12}>
        {isRegistered
          ? (
            <Grid container justify='center' alignItems='center' className={classes.imageGrid}>
              <Grid item xs={12}>
                <img src='img/verify-email.png' alt='' />
              </Grid>
            </Grid>
            )
          : (
            <RegisterCarousel />
            )}
      </Grid>
    )
  }

  return (
    <>
      <Grid className={classes.root} container spacing={0}>
        <Grid container item lg={7} xl={7} md={12} spacing={0}>
          <LeftSide />
        </Grid>
        {!matches &&
          <Grid container item lg={5} xl={5} md={12} spacing={0}>
            <RightSide />
          </Grid>}

      </Grid>
    </>
  )
}

export default Register
