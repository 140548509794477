import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  introText: {
    fontSize: 20,
    width: '100%'
  },
  btnContainer: {
    margin: '40px 0'
  },
  button: {
    marginRight: '16px',
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    }
  }
}))
