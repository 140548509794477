import React from 'react'
import { Page } from '@react-pdf/renderer'
import { KeyComponents } from '../../components'
// import styles from './style'
const AlignPage = ({ scores, behaviours }) => {
  const questions = ['How well do you understand organisational needs and prioritise business strategy?', 'How do you establish governance for learning in your organisation?']
  const title = 'Strategy and business alignment'
  const introText = 'A clear vision is critical for the success of your organisational learning strategy. What’s more, when a clear link exists between L&D strategy and organisational critical outcomes, a partnership has been developed based on a common understanding, demonstrating value becomes less important.'
  const userScore = scores[0].values[0].score
  return (
    <Page size='A4' orientation='landscape' wrap={false}>
      <KeyComponents score={userScore} title={title} introText={introText} component='align' keyQuestions={questions} keyBehaviours={behaviours} pageNumber={5} />
    </Page>
  )
}
export default AlignPage
