import React from 'react'
import { dashboardLinks } from '../navigation-drawer/mocks'
import {
  Typography
} from '@material-ui/core'
import ArrowDropUp from '@material-ui/icons/ArrowDropUpRounded'
import ArrowDropDown from '@material-ui/icons/ArrowDropDownRounded'
import { useStyles } from './style'
import { useNavigate } from 'react-router-dom'

const TeamsMenu = ({
  activeInnerLink,
  activeDashboardLink,
  setActiveDashboardLink,
  closeNavBar,
  isLGScreen,
  setActiveInnerLink,
  resultsPage,
  isAdmin,
  teamResults
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const checkEnter = (e) => e.key === 'Enter'

  const handleDashboarLink = (link) => {
    if (!resultsPage) {
      navigate('/dashboard')
    } else {
      setActiveDashboardLink(link)
      if (!isLGScreen) {
        closeNavBar()
      }
    }
  }
  const handleDashboardInnerClick = (link) => {
    setActiveInnerLink(link)
    if (!isLGScreen) {
      closeNavBar()
    }
  }
  return (
    <>
      <div className={classes.linksContainer}>
        <Typography className={classes.intro}>
          Team name
        </Typography>

        {isAdmin &&
          <span data-test='nav-team-members'>
            <Typography
              onClick={() => handleDashboarLink('team-members')}
              onKeyDown={(e) =>
                checkEnter(e) ? handleDashboarLink('team-members') : null}
              variant='body2'
              className={
                        activeDashboardLink === 'team-members'
                          ? `${classes.linksTextActive} ${isLGScreen && classes.activeShadow}`
                          : classes.linksText
                      }
              tabindex={0}
            >
              Members
            </Typography>
          </span>}
        <Typography
          className={`${classes.overline} ${classes.overlineMargin}`}
        >
          Team Results
        </Typography>
        {dashboardLinks.map((link, idx) => (
          <span key={idx} data-test={'nav-' + link.type}>
            <Typography
              key={idx}
              onClick={() => { return !teamResults ? null : handleDashboarLink(link.type) }}
              onKeyDown={(e) => { return !teamResults ? null : checkEnter(e) ? handleDashboarLink(link.type) : null }}
              variant='body2'
              className={
                        !teamResults
                          ? classes.linksDisabled
                          : activeDashboardLink === link.type
                            ? `${classes.linksTextActive} ${isLGScreen && classes.activeShadow}`
                            : classes.linksText
                      }
              tabindex={0}
            >
              {link.text}
              {link.innerLinks &&
                        link.innerLinks.length &&
                        (activeDashboardLink === link.type
                          ? (
                            <ArrowDropUp className={classes.dropdownArrow} />
                            )
                          : (
                            <ArrowDropDown className={classes.dropdownArrow} />
                            ))}
            </Typography>
            {activeDashboardLink === link.type &&
                      link.innerLinks &&
                      link.innerLinks.length && (
                        <div className={classes.innerLinksContainer}>
                          {link.innerLinks.map((innerLink, idx) => (
                            <Typography
                              key={idx}
                              onClick={() =>
                                handleDashboardInnerClick(innerLink.type)}
                              onKeyDown={(e) =>
                                checkEnter(e)
                                  ? handleDashboardInnerClick(innerLink.type)
                                  : null}
                              variant='body2'
                              className={
                                activeInnerLink === innerLink.type
                                  ? classes.innerLinksTextActive
                                  : classes.innerLinksText
                              }
                              data-test={'nav-' + innerLink.type}
                              tabindex={0}
                            >
                              {innerLink.text}
                            </Typography>
                          ))}
                        </div>
            )}
          </span>
        ))}
      </div>
    </>
  )
}
export default TeamsMenu
