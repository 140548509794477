export const surveySections = {
  0: {
    name: 'You and your organisation',
    sections: {
      personal: {
        name: '',
        header: {
          title: 'You and your organisation',
          h1: true,
          description: 'Let\'s kick off with some basics.',
          dropdown: {
            text: 'Your details enable us to build your personalised interactive dashboard, and mean we can accurately benchmark your L&D strategy against those of organisations across your industry—including those at the very top.'
          },
          img: '/img/survey-cover-1.png'
        },
        json: 'personal'
      },
      organisation: {
        name: '',
        json: 'organisation'
      }
    }
  },
  1: {
    name: 'Current goals and vision',
    sections: {
      cover: {
        title: 'Current goals and vision',
        description:
          'Now we’ll reflect on the broad outcome of your strategy over the last 12 months. By doing so, we’ll shed light on the gaps you need to plug and identify opportunities for the future.',
        img: '/img/survey-cover-2.png',
        time: 10,
        tips: [
          'What are my organisation\'s L&D goals, both short- and long-term?'
        ]
      },
      organisationgoals_1: {
        name: 'Organisation',
        header: {
          title: 'Organisation'
        },
        json: 'organisationgoals_1'
      },
      organisationgoals_2: {
        name: 'Organisation',
        json: 'organisationgoals_2'
      },
      employeegoals_1: {
        name: 'Employee',
        header: {
          title: 'Employee'
        },
        json: 'employeegoals_1'
      },
      employeegoals_2: {
        name: 'Employee',
        json: 'employeegoals_2'
      },
      resources_3: {
        name: 'Efficiency',
        header: {
          title: 'Efficiency'
        },
        json: 'resources_3'
      },
      resources_4: {
        name: 'Objectives',
        header: {
          title: 'Objectives'
        },
        json: 'resources_4'
      }
    }
  },
  2: {
    name: 'Strategy in practice and culture',
    sections: {
      cover: {
        title: 'Strategy in practice and culture',
        description:
          'It’s time to reflect on behaviours, systems, processes, rituals. Let’s take a deep dive into how your organisation actually implements its L&D practices—and how these methods are received throughout the workplace.',
        img: '/img/survey-cover-3.png',
        time: 25,
        quote: {
          text:
            'The results validated the programmes and technology we have in place, and gave us direction to focus on essential aspects such as processes and communications.',
          author: 'Riyaz Adamjee',
          role:
            'Global Director of Training, Head of Health, Safety, and Environment, Teletrac Navman'
        }
      },
      align_1: {
        name: 'Strategy and business alignment',

        header: {
          title: 'Strategy and business alignment',
          dropdown: {
            text: 'A clear vision is critical for the success of your organisational learning strategy. What’s more, when there is a clear link between L&D strategy and organisational critical outcomes, and a partnership developed on the basis of a common understanding, the need to demonstrate value becomes less important.'
          }
        },
        json: 'align_1'
      },
      align_2: {
        name: 'Strategy and business alignment',
        json: 'align_2'
      },
      listen_1: {
        name: 'Employee experience',
        header: {
          title: 'Employee experience',
          dropdown: {
            text: 'Employee experience is a key tenet of any L&D strategy. It’s underpinned by two key indicators of a high-impact learning culture: Choice - Empowering employees to make their own decisions fosters a culture in which development is self-led. Motivation - To discover what makes employees tick, leaders must explore their behaviours, practices, and everyday experiences.'
          }
        },
        json: 'listen_1'
      },
      influence_1: {
        name: 'Environment, talent and culture',
        header: {
          title: 'Environment, talent and culture',
          dropdown: {
            text: 'Another three key indicators of a high-impact learning culture are talent (e.g., L&D infused in all vertical and horizontal moves, including onboarding), work culture (e.g., empowering managers to support employees), and the organisational environment (e.g., competition, financial situation). They’re usually beyond L&D’s sphere of influence—but they shouldn’t be! Understanding them is a prerequisite for healthy and productive partnerships with multiple stakeholders.'
          }
        },
        json: 'influence_1'
      },
      influence_2: {
        name: 'Environment, talent and culture',
        json: 'influence_2'
      },
      influence_3: {
        name: 'Environment, talent and culture',
        json: 'influence_3'
      },
      enable_1: {
        name: 'L&D team capabilities',
        header: {
          dropdown: {
            text: 'Internal L&D capabilities, learning design, learning transfer, performance support, and collaboration are key indicators of a high-impact learning culture. An L&D strategy that lacks the right team capabilities to execute it will always fail. The L&D team is the critical driver of your strategy. That’s why this section of the review is the most extensive. What skills do you consider a priority for your L&D professional to ensure a modernised learning strategy?'
          },
          title: 'L&D team capabilities'
        },
        json: 'enable_1'
      },
      enable_2: {
        name: 'L&D team capabilities',
        json: 'enable_2'
      },
      enable_3: {
        name: 'L&D team capabilities',
        json: 'enable_3'
      },
      enable_4: {
        name: 'L&D team capabilities',
        json: 'enable_4'
      },
      enable_5: {
        name: 'L&D team capabilities',
        json: 'enable_5'
      },
      enable_6: {
        name: 'L&D team capabilities',
        json: 'enable_6'
      },
      engage_1: {
        name: 'Organisational engagement',
        header: {
          dropdown: {
            text: 'Managing change successfully and engaging key stakeholders is critical to success. Through engagement, employees feel more empowered, and are more committed to your organisation’s L&D vision, be they learners, facilitators, or leaders. That’s why organisational engagement is a key indicator of a high-impact learning culture.'
          },
          title: 'Organisational engagement'
        },
        json: 'engage_1'
      },
      engage_2: {
        name: 'Organisational engagement',
        json: 'engage_2'
      },
      engage_3: {
        name: 'Organisational engagement',
        json: 'engage_3'
      },
      improve_1: {
        name: 'Learning impact',
        header: {
          dropdown: {
            text: 'Shared knowledge of the impact that learning is having on performance drives the learning culture within your organisation. Gathering feedback from stakeholders, measuring effectiveness through analytics and benchmarking, and communicating successes with managers and supervisors are vital paths to understanding this impact. That’s why learning impact is a key indicator of a high-impact learning culture.'
          },
          title: 'Learning impact'
        },
        json: 'improve_1'
      },
      improve_2: {
        name: 'Learning impact',
        json: 'improve_2'
      },
      improve_3: {
        name: 'Learning impact',
        json: 'improve_3'
      }
    }
  },
  3: {
    name: 'Technology, tools and access',
    sections: {
      cover: {
        title: 'Technology, tools and access',
        description:
          'Let’s reflect on the role of technology in learning and performance support. We’ll consider how your content, infrastructure and digital tools are supporting your organisation’s L&D strategy.',
        img: '/img/survey-cover-4.png',
        time: 10,
        quote: {
          text:
            '[The Learning Performance Benchmark] gave us a benchmark of where we were and what we needed to do... I received action plans and some key steps, so it wasn’t too overwhelming or scary.',
          author: 'Tara Webber',
          role:
            'Senior Learning Development Officer, Hertfordshire County Council'
        }
      },
      technology_1: {
        name: 'Digital technology and other tools',
        header: {
          title: 'Digital technology and other tools',
          dropdown: {
            text:
              'In order to truly understand your learning ecosystem, we need to know: what digital tools your organisation uses, how it leverages technology to facilitate performance support, how people access its learning programmes and content.'

          }
        },
        json: 'technology_1'
      },
      technology_2: {
        name: 'Digital technology and other tools',
        json: 'technology_2'
      },
      technology_3: {
        name: 'Digital technology and other tools',
        json: 'technology_3'
      },
      delivery_1: {
        name: 'Delivery and access',
        header: {
          title: 'Delivery and access'
        },
        json: 'delivery_1'
      },
      delivery_2: {
        name: 'Delivery and access',
        json: 'delivery_2'
      }
    }
  },
  4: {
    name: 'Challenges',
    sections: {
      cover: {
        title: 'Challenges',
        description:
          'Sometimes you’d rather not think about them—but unless you explore your people’s pain points, and the barriers preventing them from getting the most out of your organisation’s L&D strategy, you’ll never truly thrive.',
        img: '/img/survey-cover-5.png',
        time: 10,
        quote: {
          text:
            'There was a part of me that was pleased. I needed the score to be low so I could show the business we were so far beneath where we should have been, and that we really needed to work differently.',
          author: 'Nebel Crowhurst',
          role: 'Head of People Experience, River Island'
        }
      },
      general: {
        name: 'General',
        header: {
          title: 'General',
          dropdown: {
            text:
              'Knowing your organisation’s limitations means together we can shine a light on why you’re just not witnessing the benefits you expected when you established your current L&D strategy.'

          }
        },
        json: 'general'
      },
      general_2: {
        name: 'General',
        json: 'general_2'
      },
      general_3: {
        name: 'General',
        json: 'general_3'
      },
      content: {
        name: 'Content',
        header: {
          title: 'Content'
        },
        json: 'content'
      },
      infrastructure: {
        name: 'Technology and IT infrastructure',
        header: {
          title: 'Technology and IT infrastructure'
        },
        json: 'infrastructure'
      },
      challenges_final: {
        name: 'Final questions related to challenges',
        json: 'challenges_final'
      }
    }
  },
  5: {
    name: 'Resources and impact',
    sections: {
      cover: {
        title: 'Resources and impact',
        description:
          'In this section, we’ll consider the resources you have available, how engaged your organisation is with learning and what you have managed to achieve in the last 12 months as a result of your  learning strategy.',
        img: '/img/resources-title.png',
        time: 10,
        tips: [
          'What is my L&D budget?',
          'How am I measuring the impact of my present L&D strategy?'
        ]
      },
      goals_1: {
        name: 'Resources and impact',
        header: {
          title: 'Your L&D budget and team',
          description: 'Your answers are anonymous and confidential.',
          dropdown: {
            text: 'Your answer helps us understand whether budgets for L&D are having a significant impact on your organisation. We then combine these insights with studies of strategic behaviours, technology use, and internal capabilities.\nThis is exclusively for research purposes. Remember, all answers are anonymous and confidential; they won’t be shared with anyone, not even internally.\nAll our findings are made public through research reports. '
          }
        },
        json: 'goals_1'
      },
      goals_2: {
        name: 'Resources and impact',
        header: {
          title: 'The level of learning experience in your organisation'
        },
        json: 'goals_2'
      },
      goals_3: {
        name: 'Resources and impact',
        header: {
          title: 'Success measured in the past 12 months'
        },
        json: 'goals_3'
      },
      goals_4: {
        name: 'Resources and impact',
        json: 'goals_4'
      }
    }
  },
  6: {
    name: '',
    sections: {
      final_1: {
        header: {
          title: 'Before submitting',
          h1: true,
          description: 'Lets take a minute to reflect on your review journey.',
          img: '/img/well-done.png'
        },
        json: 'final_1'
      },
      final_2: {
        header: {
          title: 'Last but not least',
          h1: true,
          img: '/img/marketing-page.png'
        },
        json: 'final_2'
      }
    }
  }
}
