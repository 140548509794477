import React from 'react'
import {
  DashboardAreaPanel
} from '../../components'
import { Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { useStyles } from './style'

const tabs = [
  {
    url: 'Align',
    label: 'Strategy and business alignment',
    keys: [
      'How well do you understand organizational needs and prioritize business strategy?',
      'How do you establish governance for learning in your organization?'
    ],
    readings: [
      {
        title: 'Driving performance and productivity',
        description:
          'By leveraging data insights, you can enhance your organisation\'s learning experience, and implement a strategy that\'s both responsive and impactful.',
        color: '#FF8200',
        img: '/img/readings/driving-performance-and-productivity.png',
        link: 4
      },
      {
        title: 'L&D’s relationship with data',
        description:
          'The ability to explore and analyse data should be one of your organisation’s highest-order priorities.',
        color: '#006FB7',
        img: '/img/readings/l_ds-relationship-with-data.png',
        link: 9
      },
      {
        title: 'The transformation journey',
        description:
          'L&D requires continual evolution and adaptation. Discover how to optimally meet your people’s needs.',
        color: '#006FB7',
        img: '/img/readings/the transformation-journey.png',
        link: 14
      }
    ],
    imgLink: '/img/key-components/strategic-business-alignment.png'
  },
  {
    url: 'Listen',
    label: 'Employee experience',
    keys: [
      'Do your people have a voice when it comes to adapting your organisation\'s learning strategy?',
      'Does your L&D consider their motivations, and allow for autonomy and choice?'
    ],
    readings: [
      {
        title: 'How to build and change habits in the workplace',
        description:
          'Adapt the working environment to increase efficiency, shape behavioural change - and effect genuine, long-lasting change.',
        color: '#B7312C',
        img: '/img/readings/how-to-build-and-change-habits-in-the-workplace.png',
        link: 8
      },
      {
        title: 'Challenging perceptions of learners',
        description:
          'The modern workplace is in a state of perpetual evolution. L&D professionals need to be primed to react accordingly.',
        color: '#006FB7',
        img: '/img/readings/challenging-perceptions-of-learners.png',
        link: 2
      },
      {
        title: 'Bridging the divide',
        description:
          'L&D means taking positive action to grow and support your learners. How can you adapt your practices for the modern workforce?',
        color: '#B1C9E8',
        img: '/img/readings/bridging-the-divide.png',
        link: 1
      }
    ],
    imgLink: '/img/key-components/employee-experience.png'
  },
  {
    url: 'Influence',
    label: 'Environment, talent and culture',
    keys: [
      'Do you understand the determinants - both internal and external - affecting the success of your learning strategy?',
      'Are you taking active measures to address these factors?'
    ],
    readings: [
      {
        title: 'Who moved my skills?',
        description:
          'Every employee faces competing priorities, but you can ameliorate this challenge by nurturing a culture of both upskilling and reskilling.',
        color: '#B7312C',
        img: '/img/readings/who-moved-my-skills.png',
        link: 16
      },
      {
        title: 'Driving the new learning organisation',
        description:
          'Success means addressing the four strategic levers of business: growth, transformation, productivity, and profitability.',
        color: '#FF8200',
        img: '/img/readings/driving-the-new-learning organisation.png',
        link: 5
      },
      {
        title: 'Risk in the C-suite',
        description:
          'Senior executives need to encourage a top-down, future-focused approach to their organisation’s people strategy to ensure sustainability.',
        color: '#B7312C',
        img: '/img/readings/risk-in-the-C-suite.png',
        link: 13
      }
    ],
    imgLink: '/img/key-components/environment-talent-culture.png'
  },
  {
    url: 'Enable',
    label: 'L&D team capabilities',
    keys: [
      'Does your team have the capacity to enable learning in the flow of work?',
      'Is evidence-based practice embedded in how your organisation makes decisions about learning and performance support?'
    ],
    readings: [
      {
        title: 'Going beyond ticking the box',
        description:
          'Compliance should be a key focal point for L&D training. And if you do it right, the benefits permeate your entire organisation.',
        color: '#B7312C',
        img: '/img/readings/going-beyond-ticking-the-box.png',
        link: 7
      },
      {
        title: 'Professionalising learning and development',
        description:
          'The business landscapes is more volatile than ever. Is your organisation primed to adapt to the relentless pace of change?',
        color: '#FF8200',
        img: '/img/readings/professionialising learning-and-development.png',
        link: 12
      },
      {
        title: 'The evolution of 70:20:10',
        description:
          'What insights can you draw from the framework that’s taken the world by storm?',
        color: '#FF8200',
        img: '/img/readings/the-evolution-of-70-20-10.png',
        link: 15
      }
    ],
    imgLink: '/img/key-components/team-capabilities.png'
  },
  {
    url: 'Engage',
    label: 'Organisational engagement',
    keys: [
      'Have you identified stakeholders you need to work with to deliver your learning strategy?',
      'How can you more effectively connect with these people to put your strategy into practice?'
    ],
    readings: [
      {
        title: 'Challenging perceptions of learners',
        description:
          'The modern workplace is in a state of perpetual evolution. L&D professionals need to be primed to react accordingly.',
        color: '#006FB7',
        img: '/img/readings/challenging-perceptions-of-learners.png',
        link: 2
      },
      {
        title:
          'Back to the future: why tomorrow’s workforce needs a learning culture',
        description:
          'It’s great to see more organisations investing in L&D—but why are so many of them still witnessing impact drop? Discover ways to build a high-impact learning culture—and find out how to ensure its success.',
        color: '#B1C9E8',
        img: '/img/readings/back-to-the-future.png',
        link: 6
      },
      {
        title: 'Driving leadership capability',
        description:
          'Explore how top-performing businesses develop their leaders, and draw insights for driving leadership capabilities in your own organisation.',
        color: '#B7312C',
        img: '/img/readings/driving-leadership-capability.png',
        link: 3
      }
    ],
    imgLink: '/img/key-components/organisational-engagement.png'
  },
  {
    url: 'Improve',
    label: 'Learning impact',
    keys: [
      'Is your organisation fostering a culture of continual improvement?',
      'How is your L&D strategy delivering on the four strategic levers of business (growth, transformation, productivity, profitability)?'
    ],
    readings: [
      {
        title: 'L&D’s relationship with data',
        description:
          'The ability to explore and analyse data should be one of your organisation’s highest-order priorities.',
        color: '#006FB7',
        img: '/img/readings/l_ds-relationship-with-data.png',
        link: 9
      },
      {
        title: 'Driving performance and productivity',
        description:
          'By leveraging data insights, you can enhance your organisation\'s learning experience, and implement a strategy that\'s both responsive and impactful.',
        color: '#FF8200',
        img: '/img/readings/driving-performance-and-productivity.png',
        link: 4
      },
      {
        title: 'Making an impact: how L&D leaders can demonstrate value',
        description:
          'How is technology shaping the evolution of the workplace learning model—and how is this affecting L&D?',
        color: '#B1C9E8',
        img: '/img/readings/making-an-impact.png',
        link: 11
      }
    ],
    imgLink: '/img/key-components/learning-impact.png'
  }
]

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && { ...children }}
    </div>
  )
}

const DashboardAreas = ({ innerPage, setTab, areasScores, profile, behaviours }) => {
  const classes = useStyles()

  const handleChangeTab = (_, newValue) => {
    setTab(newValue)
  }

  const currentArea = areasScores[innerPage]
  const behaviourData = behaviours[innerPage]

  return (
    <Grid className={classes.root} container spacing={3}>
      <Grid item xs={12}>
        <Typography variant='h1' gutterBottom>
          Exploring the six key components
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          classes={{ flexContainer: classes.tabs }}
          variant='fullWidth'
          value={innerPage}
          onChange={handleChangeTab}
          aria-label='Six components tabs'
        >
          {tabs.map(tab => (
            <Tab
              key={tab.url}
              value={tab.url}
              label={tab.label}
              id={`wrapped-tab-${tab.url}`}
              className={classes.tab}
              wrapped
              data-test={`btn-tab-${tab.label
                .toLowerCase()
                .replace(/ /g, '-')
                .replace(/&/g, '')
                .replace(/,/g, '')}`}
            />
          ))}
        </Tabs>
        {tabs.map(tab => (
          <TabPanel key={tab.url} value={innerPage} index={tab.url}>
            <DashboardAreaPanel
              title={tab.label}
              keys={tab.keys}
              readings={tab.readings}
              score={currentArea}
              image={tab.imgLink}
              profile={profile}
              behaviours={behaviourData}
            />
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  )
}

export default DashboardAreas
