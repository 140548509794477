import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  main: {
    marginTop: '64px',
    padding: '0px 100px',
    // marginLeft: '254px',
    transition: '0.25s all ease',
    '@media (min-width: 1200px)': {
      padding: '0px 100px 70px'
    },
    '@media (max-width: 1200px)': {
      padding: '0px 48px',
      marginTop: '100px'
    },
    backgroundColor: '#EEEFF1',
    width: '100%'
  },
  mainMD: {
    padding: '24px',
    transition: 'all 0.25s ease-in-out',
    marginTop: '64px',
    marginLeft: 0,
    backgroundColor: '#EEEFF1',
    width: '100%'
  },
  title: {
    textAlign: 'center'
  },
  cardsBox: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}))
