import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { registerSlice } from '../../reducers'
import { useStyles } from './style'
import Auth from '@aws-amplify/auth'
import { basedURL } from '../../helpers/domain'
import { datadogRum } from '@datadog/browser-rum'

const VerifyEmail = () => {
  const username = useSelector(registerSlice.selectors.selectUsername)
  const classes = useStyles()

  const handleResendVerify = async () => {
    try {
      await Auth.resendSignUp(username, {
        verifyUrl: window.location.protocol + '//' + basedURL() + '/verify'
      })
    } catch (e) {
      datadogRum.addError(e)
      console.log('resendEmail error:', e)
    }
  }

  const handleContactUs = () => {
    window.location = 'mailto:lpbenchmark@mindtools.com'
  }

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Typography data-test='authPageHeader' variant='h1'>
          Please verify your account
        </Typography>
        <Typography variant='body1' className={classes.text}>
          Thanks for creating an account! A verification email has been sent to{' '}
          <b>{username}</b>. Simply click on the link to get started on
          completing your review.
          <Typography variant='body1' className={classes.subtext}>
            If you don't see the email, please check your spam folder.
          </Typography>
        </Typography>
        <div className={classes.buttons}>
          <Button variant='contained' color='secondary' onClick={handleResendVerify}>
            Resend email
          </Button>
          <Button variant='outlined' className={classes.spacing} onClick={handleContactUs}>
            Contact Us
          </Button>
        </div>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

export default VerifyEmail
