import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'
import { datadogRum } from '@datadog/browser-rum'

// Store
export const user = createSlice({
  name: reducerName,
  initialState: {
    userData: {},
    menuLevel: parseInt(localStorage.getItem('menuLevel')) || 0,
    activeMenuSection: localStorage.getItem('activeMenuSection') || 'home',
    featureToggles: {}
  },
  reducers: {
    setUserData: (state, { payload }) => {
      state.userData = payload
    },
    setMenuLevel: (state, { payload }) => {
      state.menuLevel = payload
      localStorage.setItem('menuLevel', Number(payload))
    },
    setActiveMenuSection: (state, { payload }) => {
      state.activeMenuSection = payload
      localStorage.setItem('activeMenuSection', payload)
    },
    createUserDataError: () => {
      datadogRum.addTiming('create_user_data_error')
      datadogRum.addError(new Error('Error: createUserData'))
    },
    getUserDataError: () => {
      datadogRum.addTiming('get_user_data_error')
      datadogRum.addError(new Error('Error: getUserData'))
    },
    sendContactEmailError: () => {
      datadogRum.addTiming('send_email_error')
      datadogRum.addError(new Error('Error: sendContactEmail'))
    },
    updateUserDataOnRegisterError: () => {
      datadogRum.addTiming('update_on_register_error')
      datadogRum.addError(new Error('Error: updateUserDataOnRegister'))
    },
    setFeatureToggles: (state, { payload }) => {
      console.log('setting feature toggles...')
      state.featureToggles = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setUserData,
  setMenuLevel,
  setActiveMenuSection,
  createUserDataError,
  getUserDataError,
  sendContactEmailError,
  updateUserDataOnRegisterError,
  setFeatureToggles
} = user.actions

// Reducer
export default user.reducer
