import React from 'react'
import { useStyles } from './style'
import { Link } from 'react-router-dom'

const MailtoLink = ({ email, ...props }) => {
  const classes = useStyles()

  return (
    <Link
      className={classes.root}
      to='#'
      onClick={(e) => {
        window.location = `mailto:${email}`
        e.preventDefault()
      }}
      data-test={props['data-test']}
    >
      {email}
    </Link>
  )
}

export default MailtoLink
