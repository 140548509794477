import React from 'react'
import {
  Dialog,
  Typography,
  Button
} from '@material-ui/core'
import { useStyles } from './style'

const ProfileDialog = ({
  open,
  handleFinish,
  unanswered
}) => {
  const classes = useStyles()
  const questions = {
    Role: 'Your primary role',
    Region: 'Your organisation’s head office location',
    Size: 'Your organisation’s size',
    Spread: 'Your organisation’s geographical spread',
    Industry: 'Your organisation’s primary business'
  }
  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography className={classes.text}>Please make sure the following fields are complete, so that we can accurately benchmark you against similar organisations:</Typography>
        <ul>

          {unanswered.map((name, i) => (
            <li key={i} className={classes.listText}>{questions[name]}</li>
          ))}
        </ul>
        <div className={classes.buttons}>
          <Button className={classes.rightButton} variant='contained' color='secondary' onClick={handleFinish}>Ok</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ProfileDialog
