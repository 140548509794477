import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  imgHover: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '90%',
    height: 'auto',
    cursor: 'pointer'
  },
  icon: {
    color: 'rgba(37, 55, 70, 1)',
    height: '24px'
  },
  sectionTitle: {
    letterSpacing: '0.5px'
  },
  completionText: {
    whiteSpace: 'nowrap',
    '@media (max-width:960px)': {

      marginLeft: '-5px'
    },
    '@media (min-width: 1280px) and (max-width: 1600px)': {
      marginLeft: '-10px'
    }
  },
  homePaper: {
    padding: '16px 24px',
    minHeight: '335px',
    '& h2': {
      marginTop: 0
    }
  },
  homePaperContent: {
    minHeight: '230px'
  },
  footer: {
    paddingTop: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    textTransform: 'uppercase'
  },
  footerButton: {
    cursor: 'pointer'
  },
  NumberIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '20px',
    width: '20px',
    border: '2px solid rgba(37, 55, 70, 1)',
    borderRadius: '50%',
    marginLeft: '2px',
    marginTop: '2px',
    '& p': {
      fontWeight: 700,
      fontSize: '10px',
      color: 'rgba(37, 55, 70, 1)'
    }
  },
  hr: {
    border: '1px solid rgba(37, 55, 70, 0.12)'
  }

}))
