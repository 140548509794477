import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { AppToolbar, LPBLink } from '../../components'
import { useStyles } from './style'

const TermsAndConditions = () => {
  const classes = useStyles()

  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return null
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <AppToolbar />
      <Grid
        className={classes.root}
        container
        direction='column'
        justify='space-between'
        alignItems='flex-start'
      >
        <Grid item xs={8}>
          <Typography className={classes.headTitle} variant='h1'>
            Terms of website use
          </Typography>
          <Typography className={classes.headContent} variant='body1'>
            This terms of use page (together with the documents referred to in
            it) tells you the terms of use on which you may make use of our
            website{' '}
            <a
              className={classes.linkExternal}
              href='https://lpbenchmark.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.lpbenchmark.mindtools.com
            </a>{' '}
            (our site), whether as a guest or a registered user. Use of our site
            includes accessing, browsing or registering to use our site.
          </Typography>
        </Grid>
        <Grid className={classes.boxIntro} item xs={8}>
          <Typography className={classes.content} variant='body1'>
            Please read these terms of use carefully before you start to use our
            site, as these will apply to your use of our site. We recommend that
            you print a copy of this for future reference.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            By using our site, you confirm that you accept these terms of use
            and that you agree to comply with them.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you do not agree to these terms of use, you must not use our
            site.
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography className={classes.paragraph} variant='body1'>
            Other applicable terms
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            These terms of use refer to the following additional terms, which
            also apply to your use of our site:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              <LPBLink className={classes.linkLPB} target='_blank' rel='noopener noreferrer' to='/privacy-policy'>
                Our Privacy Policy
              </LPBLink>
              , which sets out the terms on which we process any personal data
              we collect from you, or that you provide to us. By using our site,
              you consent to such processing and you warrant that all data
              provided by you is accurate.
            </li>
            <li className={classes.paragraph}>
              Our Acceptable Use Policy, which sets out the permitted uses and
              prohibited uses of our site. When using our site, you must comply
              with this Acceptable Use Policy.
            </li>
            <li className={classes.paragraph}>
              <LPBLink className={classes.linkLPB} target='_blank' rel='noopener noreferrer' to='/cookies-policy'>
                Our Cookie Policy
              </LPBLink>
              , which sets out information about the cookies on our site.
            </li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            By using a log-in for or visiting the{' '}
            <a
              className={classes.linkExternal}
              href='https://lpbenchmark.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.lpbenchmark.mindtools.com
            </a>{' '}
            Website (the “Website”) you are accepting and consenting to the
            practices described in this policy.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Information about us
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            <a
              className={classes.linkExternal}
              href='https://lpbenchmark.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.lpbenchmark.mindtools.com
            </a>{' '}
            is a site operated by Emerald Works Ltd ("We"). We are registered in
            Scotland under company number SC202102 and have our registered
            office at Emerald Works Limited, Level 1, 50 Frederick Street,
            Edinburgh, Scotland EH2 1EX.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Changes to these terms
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We may revise these terms of use at any time by amending this page.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Please check this page from time to time to take notice of any
            changes we made, as they are binding on you.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Changes to our site
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We may update our site from time to time, and may change the content
            at any time. However, please note that any of the content on our
            site may be out of date at any given time, and we are under no
            obligation to update it.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We do not guarantee that our site, or any content on it, will be
            free from errors or omissions.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Accessing our site
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Our site is made available free of charge.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We do not guarantee that our site, or any content on it, will always
            be available or be uninterrupted. Access to our site is permitted on
            a temporary basis. We may suspend, withdraw, discontinue or change
            all or any part of our site without notice. We will not be liable to
            you if for any reason our site is unavailable at any time or for any
            period.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You are responsible for making all arrangements necessary for you to
            have access to our site.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You are also responsible for ensuring that all persons who access
            our site through your internet connection are aware of these terms
            of use and other applicable terms and conditions, and that they
            comply with them.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Your account and password
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you choose, or you are provided with, a user identification code,
            password or any other piece of information as part of our security
            procedures, you must treat such information as confidential. You
            must not disclose it to any third party.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We have the right to disable any user identification code or
            password, whether chosen by you or allocated by us, at any time, if
            in our reasonable opinion you have failed to comply with any of the
            provisions of these terms of use. If you know or suspect that anyone
            other than you knows your user identification code or password, you
            must promptly notify us at{' '}
            <a
              className={classes.linkExternal}
              href='mailto:beinspired@mindtools.com?subject=Terms%20&amp;%20Conditions%20contact'
            >beinspired@mindtools.com
            </a>.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Intellectual property rights
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We are the owner or the licensee of all intellectual property rights
            in our site, and in the material published on it. Those works are
            protected by copyright laws and treaties around the world. All such
            rights are reserved.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You may print off one copy, and may download extracts, of any
            page(s) from our site for your personal use, and you may draw the
            attention of others within your organization to content posted on
            our site.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You must not modify the paper or digital copies of any materials you
            have printed off or downloaded in any way, and you must not use any
            illustrations, photographs, video or audio sequences or any graphics
            separately from any accompanying text.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Our status (and that of any identified contributors) as the authors
            of content on our site must always be acknowledged.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You must not use any part of the content on our site for commercial
            purposes without obtaining a license to do so from us or our
            licensors.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you print off, copy or download any part of our site in breach of
            these terms of use, your right to use our site will cease
            immediately and you must, at our option, return or destroy any
            copies of the materials you have made.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            No reliance on information
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The content on our site is provided for general information only. It
            is not intended to amount to advice on which you should rely. You
            must obtain professional or specialist advice before taking, or
            refraining from, any action on the basis of the content on our site.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Although we make reasonable efforts to update the information on our
            site, we make no representations, warranties or guarantees, whether
            express or implied, that the content on our site is accurate,
            complete or up-to-date.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Limitation of our liability
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Nothing in these terms of use excludes or limits our liability for
            death or personal injury arising from our negligence, or our fraud
            or fraudulent misrepresentation, or any other liability that cannot
            be excluded or limited by English law.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            To the extent permitted by law, we exclude all conditions,
            warranties, representations or other terms that may apply to our
            site or any content on it, whether express or implied.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will not be liable to any user for any loss or damage, whether in
            contract, tort (including negligence), breach of statutory duty, or
            otherwise, even if foreseeable, arising under or in connection with:
            use of, or inability to use, our site; or use of or reliance on any
            content displayed on our site.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you are a business user, please note that, in particular, we will
            not be liable for: loss of profits, sales, business, or revenue;
            business interruption; loss of anticipated savings; loss of business
            opportunity, goodwill or reputation; or any indirect or
            consequential loss or damage.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you are a consumer user, please note that we only provide our
            site for domestic and private use. You agree not to use our site for
            any commercial or business purposes, and we have no liability to you
            for any loss of profit, loss of business, business interruption, or
            loss of business opportunity.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will not be liable for any loss or damage caused by a virus,
            distributed denial-of-service attack, or other technologically
            harmful material that may infect your computer equipment, computer
            programs, data or other proprietary material due to your use of our
            site or to your downloading of any content on it, or on any website
            linked to it.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We assume no responsibility for the content of websites linked on
            our site. Such links should not be interpreted as endorsement by us
            of those linked websites. We will not be liable for any loss or
            damage that may arise from your use of them.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Different limitations and exclusions of liability will apply to
            liability arising as a result of the supply of any goods by use to
            you, which will be set out in our Terms and Conditions of supply.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Uploading content to our site
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Whenever you make use of a feature that allows you to upload content
            to our site, or to make contact with other users of our site, you
            must comply with the content standards set out in our Acceptable Use
            Policy.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You warrant that any such contribution does comply with those
            standards, and you will be liable to us and indemnify us for any
            breach of that warranty. If you are a consumer user, this means you
            will be responsible for any loss or damage we suffer as a result of
            your breach of warranty.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Any content you upload to our site will be considered
            non-confidential and non-proprietary, and we have the right to use,
            copy, distribute and disclose to third parties any such content for
            any purpose.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We also have the right to disclose your identity to any third party
            who is claiming that any content posted or uploaded by you to our
            site constitutes a violation of their intellectual property rights,
            or of their right to privacy.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will not be responsible, or liable to any third party, for the
            content or accuracy of any content posted by you or any other user
            of our site.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We have the right to remove any posting you make on our site if, in
            our opinion, your post does not comply with the content standards
            set out in our Acceptable Use Policy .
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The views expressed by other users on our site do not represent our
            views or values.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Viruses
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We do not guarantee that our site will be secure or free from bugs
            or viruses.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You are responsible for configuring your information technology,
            computer programs and platform in order to access our site. You
            should use your own virus protection software.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You must not misuse our site by knowingly introducing viruses,
            trojans, worms, logic bombs or other material that is malicious or
            technologically harmful. You must not attempt to gain unauthorised
            access to our site, the server on which our site is stored or any
            server, computer or database connected to our site. You must not
            attack our site via a denial-of-service attack or a distributed
            denial-of service attack. By breaching this provision, you would
            commit a criminal offence under the Computer Misuse Act 1990. We
            will report any such breach to the relevant law enforcement
            authorities and we will co-operate with those authorities by
            disclosing your identity to them. In the event of such a breach,
            your right to use our site will cease immediately.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Linking to our site
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You may link to our home page, provided you do so in a way that is
            fair and legal and does not damage our reputation or take advantage
            of it.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You must not establish a link in such a way as to suggest any form
            of association, approval or endorsement on our part where none
            exists.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You must not establish a link to our site in any website that is not
            owned by you.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Our site must not be framed on any other site, nor may you create a
            link to any part of our site other than the home page.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We reserve the right to withdraw linking permission without notice.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The website in which you are linking must comply in all respects
            with the content standards set out in our Acceptable Use Policy.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you wish to make any use of content on our site other than that
            set out above, please contact{' '}
            <a
              className={classes.linkExternal}
              href='mailto:beinspired@mindtools.com?subject=Terms%20&amp;%20Conditions%20contact'
            >beinspired@mindtools.com
            </a>.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Third party links and resources in our site
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Where our site contains links to other sites and resources provided
            by third parties, these links are provided for your information
            only.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We have no control over the contents of those sites or resources.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Applicable law
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you are a consumer, please note that these terms of use, its
            subject matter and its formation, are governed by English law. You
            and we both agree to that the courts of England and Wales will have
            exclusive jurisdiction.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you are a business, these terms of use, its subject matter and
            its formation (and any non-contractual disputes or claims) are
            governed by English law. We both agree to the exclusive jurisdiction
            of the courts of England and Wales.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Acceptable Use Policy
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            This acceptable use policy sets out the terms between you and us
            under which you may access our website{' '}
            <a
              className={classes.linkExternal}
              href='https://lpbenchmark.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.lpbenchmark.mindtools.com
            </a>{' '}
            (our site). This acceptable use policy applies to all users of, and
            visitors to, our site.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Your use of our site means that you accept, and agree to abide by,
            all the policies in this acceptable use policy, which supplement our
            terms of website use.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            <a
              className={classes.linkExternal}
              href='https://lpbenchmark.mindtools.com/'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.lpbenchmark.mindtools.com
            </a>{' '}
            is a site operated by Emerald Works Ltd ("We"). We are registered in
            Scotland under company number SC202102 and have our registered
            office at Emerald Works Limited, Level 1, 50 Frederick Street,
            Edinburgh, Scotland EH2 1EX.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You may use our site only for lawful purposes. You may not use our
            site:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              In any way that breaches any applicable local, national or
              international law or regulation.
            </li>
            <li className={classes.paragraph}>
              In any way that is unlawful or fraudulent, or has any unlawful or
              fraudulent purpose or effect.
            </li>
            <li className={classes.paragraph}>
              For the purpose of harming or attempting to harm minors in any
              way.
            </li>
            <li className={classes.paragraph}>
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our content standards.
            </li>
            <li className={classes.paragraph}>
              To transmit, or procure the sending of, any unsolicited or
              unauthorized advertising or promotional material or any other form
              of similar solicitation (spam).
            </li>
            <li className={classes.paragraph}>
              To knowingly transmit any data, send or upload any material that
              contains viruses, Trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other harmful programs or similar
              computer code designed to adversely affect the operation of any
              computer software or hardware.
            </li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            You also agree:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              Not to reproduce, duplicate, copy or re-sell any part of our site
              in contravention of the provisions of our terms of website use.
            </li>
            <li className={classes.paragraph}>
              Not to access without authority, interfere with, damage or
              disrupt:
            </li>
            <li className={classes.paragraph}>Any part of our site;</li>
            <li className={classes.paragraph}>
              Any equipment or network on which our site is stored;
            </li>
            <li className={classes.paragraph}>
              Any software used in the provision of our site; or
            </li>
            <li className={classes.paragraph}>
              Any equipment or network or software owned or used by any third
              party.
            </li>
          </ul>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Interactive Services
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We may from time to time provide interactive services on our site,
            including, without limitation:
          </Typography>
          <ul>
            <li className={classes.paragraph}>Chat rooms</li>
            <li className={classes.paragraph}>Bulletin boards</li>
            <li className={classes.paragraph}>Forums</li>
            <li className={classes.paragraph}>Blog comment boards</li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            Where we do provide any interactive service, we will provide clear
            information to you about the kind of service offered, if it is
            moderated and what form of moderation is used (including whether it
            is human or technical).
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will do our best to assess any possible risks for users (and, in
            particular, for children) from third parties when they use any
            interactive service provided on our site, and we will decide in each
            case whether it is appropriate to use moderation of the relevant
            service (including what kind of moderation to use) in the light of
            those risks. However, we are under no obligation to oversee, monitor
            or moderate any interactive service we provide on our site, and we
            expressly exclude our liability for any loss or damage arising from
            the use of any interactive service by a user in contravention of our
            content standards, whether the service is moderated or not.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            The use of any of our interactive services by a minor is subject to
            the consent of their parent or guardian. We advise parents who
            permit their children to use an interactive service that it is
            important that they communicate with their children about their
            safety online, as moderation is not foolproof. Minors who are using
            any interactive service should be made aware of the potential risks
            to them.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Where we do moderate an interactive service, we will normally
            provide you with a means of contacting the moderator, should a
            concern or difficulty arise.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Content standards
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            These content standards apply to any and all material which you
            contribute to our site (contributions), and to any interactive
            services associated with it.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            You must comply with the spirit of the following standards as well
            as the letter. The standards apply to each part of any contribution
            as well as to its whole.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Contributions must:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              Be accurate (where they state facts)
            </li>
            <li className={classes.paragraph}>
              Be genuinely held (where they state opinions)
            </li>
            <li className={classes.paragraph}>
              Comply with applicable law in the UK and in any country from which
              they are posted
            </li>
            <li className={classes.paragraph}>Blog comment boards</li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            Contributions must not:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              Contain any material which is defamatory of any person
            </li>
            <li className={classes.paragraph}>
              Contain any material which is obscene, offensive, hateful or
              inflammatory
            </li>
            <li className={classes.paragraph}>
              Promote sexually explicit material
            </li>
            <li className={classes.paragraph}>Promote violence</li>
            <li className={classes.paragraph}>
              Promote discrimination based on race, sex, religion, nationality,
              disability, sexual orientation or age
            </li>
            <li className={classes.paragraph}>
              Infringe any copyright, database right or trade mark of any other
              person
            </li>
            <li className={classes.paragraph}>
              Promote sexually explicit material
            </li>
            <li className={classes.paragraph}>
              Be made in breach of any legal duty owed to a third party, such as
              a contractual duty or a duty of confidence
            </li>
            <li className={classes.paragraph}>Promote any illegal activity</li>
            <li className={classes.paragraph}>
              Be threatening, abuse or invade another’s privacy, or cause
              annoyance, inconvenience or needless anxiety
            </li>
            <li className={classes.paragraph}>
              Be likely to harass, upset, embarrass, alarm or annoy any other
              person
            </li>
            <li className={classes.paragraph}>
              Be used to impersonate any person, or to misrepresent your
              identity or affiliation with any person
            </li>
            <li className={classes.paragraph}>
              Give the impression that they emanate from us, if this is not the
              case
            </li>
            <li className={classes.paragraph}>
              Advocate, promote or assist any unlawful act such as (by way of
              example only) copyright infringement or computer misuse
            </li>
          </ul>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Suspension and termination
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We will determine, in our discretion, whether there has been a
            breach of this acceptable use policy through your use of our site.
            When a breach of this policy has occurred, we may take such action
            as we deem appropriate.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Failure to comply with this acceptable use policy constitutes a
            material breach of the terms of use upon which you are permitted to
            use our site, and may result in our taking all or any of the
            following actions:
          </Typography>
          <ul>
            <li className={classes.paragraph}>
              Immediate, temporary or permanent withdrawal of your right to use
              our site
            </li>
            <li className={classes.paragraph}>
              Immediate, temporary or permanent removal of any posting or
              material uploaded by you to our site
            </li>
            <li className={classes.paragraph}>Issue of a warning to you</li>
            <li className={classes.paragraph}>
              Legal proceedings against you for reimbursement of all costs on an
              indemnity basis (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach
            </li>
            <li className={classes.paragraph}>
              Further legal action against you
            </li>
            <li className={classes.paragraph}>
              Disclosure of such information to law enforcement authorities as
              we reasonably feel is necessary
            </li>
          </ul>
          <Typography className={classes.paragraph} variant='body1'>
            We exclude liability for actions taken in response to breaches of
            this acceptable use policy. The responses described in this policy
            are not limited, and we may take any other action we reasonably deem
            appropriate.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.subTitle} variant='h3'>
            Changes to the acceptable use policy
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We may revise this acceptable use policy at any time by amending
            this page. You are expected to check this page from time to time to
            take notice of any changes we make, as they are legally binding on
            you. Some of the provisions contained in this acceptable use policy
            may also be superseded by provisions or notices published elsewhere
            on our site.
          </Typography>
        </Grid>
        <Grid className={classes.box} item xs={8}>
          <Typography className={classes.title} variant='h3'>
            Contact us
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            To contact us, please email{' '}
            <a
              className={classes.linkExternal}
              href='mailto:beinspired@mindtools.com?subject=Terms%20&amp;%20Conditions%20contact'
            >beinspired@mindtools.com
            </a>.{' '}
            Thank you for visiting our site.
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default TermsAndConditions
