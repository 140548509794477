import React, { useRef } from 'react'
import {
  FormControl,
  TextField,
  Grid,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import { useStyles } from './style'
import Auth from '@aws-amplify/auth'
import {
  enableLoginSend,
  validateEmail,
  validatePassword
} from '../../libs/formUtils'
import { useNavigate } from 'react-router-dom'
import { LPBLink } from '../../components'
import { useSession } from '@emerald-works/react-auth'
import { useConnectionContext, useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { userSlice } from '../../reducers'
import { datadogRum } from '@datadog/browser-rum'
import { useDispatch } from 'react-redux'
const LoginAccountForm = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useNavigate()
  const session = useSession()
  const context = useConnectionContext()
  const hj = window.hj
  const dataLayer = window.dataLayer || []
  const [createUserData] = useEvent([userSlice.eventBus.createUserData])

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      username: '',
      password: ''
    }
  })

  const currentUsername = watch('username')
  const currentPassword = watch('password')

  const usernameRef = useRef(null)
  const passwordRef = useRef(null)

  const isDisabled = enableLoginSend(currentUsername, currentPassword)

  const usernameError = {
    error: !isDisabled && !validateEmail(currentUsername),
    text:
      !isDisabled &&
      !validateEmail(currentUsername) &&
      'Must be a valid email address.'
  }

  const passwordError = {
    error: !isDisabled && !validatePassword(currentPassword),
    text:
      !isDisabled &&
      !validateEmail(currentPassword) &&
      'Use eight or more characters with a mix of letters, numbers and symbols.'
  }

  const [showPassword, setShowPassword] = React.useState(false)
  const [isAuthenticating, setIsAuthenticating] = React.useState(false)
  const [authenticationFeedback, setAuthenticationFeedback] = React.useState({})

  const handleShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const onSubmit = async data => {
    const { username, password } = data
    setIsAuthenticating(true)
    try {
      const user = await Auth.signIn({
        username,
        password
      })
      datadogRum.setUser({
        id: user.attributes.sub
      })
      await session.updateSessionContext()
      context.reloadConnection()
      setAuthenticationFeedback({ success: true })

      const isUserFirstLogin = user.attributes['custom:isFirstLogin'] === undefined
      const hasResults = user.attributes['custom:reviewCompleted'] === 'true'
      hj('stateChange', '/lpb-login')
      dataLayer.push({
        event: 'stateChange',
        attributes: {
          path: '/lpb-login'
        }
      })
      if (isUserFirstLogin) {
        await Auth.updateUserAttributes(user, {
          'custom:isFirstLogin': 'false'
        })
        if (!hasResults) {
          history('/review')
          dispatch(userSlice.actions.setActiveMenuSection('you'))
          dispatch(userSlice.actions.setMenuLevel(1))
        } else {
          createUserData.trigger({ email: user.attributes.email })
          history('/')
        }
      } else {
        if (user.attributes.email_verified) history('/')
        else history('/account')
      }
    } catch (e) {
      setAuthenticationFeedback({ error: true, message: e.message || e })
      datadogRum.addError(e)
      console.log('Login error', e)
    }
    setIsAuthenticating(false)
  }

  useEventsOnViewLoad(() => {
    if (usernameRef.current) {
      setValue('username', usernameRef.current.value)
    }
    if (passwordRef.current) {
      setValue('password', passwordRef.current.value)
    }
  }, [])

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Typography data-test='authPageHeader' variant='h1'>
          Log in
        </Typography>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              ref={usernameRef}
              data-test='emailField'
              label='Organisation email'
              variant='outlined'
              name='username'
              type='text'
              inputRef={register({
                required: true,
                // eslint-disable-next-line no-useless-escape
                pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })}
              inputProps={{ 'aria-label': 'Organisational email' }}
              required
              error={usernameError.error || authenticationFeedback.error}
              helperText={usernameError.text || authenticationFeedback.message}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              ref={passwordRef}
              label='Password'
              type={showPassword ? 'text' : 'password'}
              variant='outlined'
              name='password'
              data-test='passwordField'
              required
              error={passwordError.error}
              helperText={'' || passwordError.text}
              // eslint-disable-next-line no-useless-escape
              inputRef={register({
                required: true,
                pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*\W).{8,20}/
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      data-test='showPassword'
                      tabIndex='-1'
                      onClick={handleShowPassword}
                      aria-label='Show password'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{ 'aria-label': 'Password' }}
            />
            <p className={classes.termsText}>
              <LPBLink data-test='lnk-forgot' to='/forgot-password'>
                Forgotten your password?
              </LPBLink>
            </p>
          </FormControl>
          {
            !isAuthenticating
              ? (
                <Button
                  id='btn-log-in'
                  className={classes.primaryButton}
                  data-test='btn-login'
                  color='secondary'
                  type='submit'
                  variant='contained'
                  disabled={isDisabled}
                >
                  Log in
                </Button>
                )
              : (
                <CircularProgress />
                )
          }
          <p className={classes.loginText}>
            Not registered yet?{' '}
            <LPBLink data-test='lnk-signUp' to='/register'>
              Sign up.
            </LPBLink>
          </p>
        </form>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

export default LoginAccountForm
