import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '24px'
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '32px'
  },
  takeaways: {
    backgroundColor: '#EEEFF1',
    boxShadow: '-90px 0px 0px 0px #EEEFF1,90px 0px 0px 0px #EEEFF1'
  },
  takeawaysTitle: {
    color: '#253746',
    margin: '24px 0',
    fontWeight: 700,
    lineHeight: 1.2,
    fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '1.667rem'
    },
    '@media (min-width:900px)': {
      fontSize: '1.875rem'
    },
    '@media (min-width:1200px)': {
      fontSize: '2.0833rem'
    }
  },
  chartsContainer: {
    padding: '32px 12px 64px !important',
    width: '100%',
    minHeight: '295px'
  }
}))
