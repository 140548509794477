import React from 'react'
import { useStyles } from './style'
import {
  AppToolbar,
  AccountForm,
  PageAlert,
  PasswordDialog
} from '../../components'
import { Grid, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { surveySlice } from '../../reducers'
import { useForm } from 'react-hook-form'

const AccountPage = () => {
  const classes = useStyles()
  const [isPasswordDialogOpen, setPasswordDialogOpen] = React.useState(false)
  const [showUserAlert, setShowUserAlert] = React.useState(false)
  const [showPasswordAlert, setShowPasswordAlert] = React.useState(false)

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)

  const { register, watch, reset } = useForm({
    defaultValues: {
      code: '',
      currentPassword: '',
      password: '',
      confirmPassword: ''
    }
  })

  const handleDialogClose = () => {
    setPasswordDialogOpen(false)
  }

  const handleDialogOpen = () => {
    setPasswordDialogOpen(true)
  }

  const onCloseUserAlert = () => {
    setShowUserAlert(false)
  }

  const onClosePasswordAlert = () => {
    setShowPasswordAlert(false)
  }

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        hideHamburger
      />
      <Grid container>

        <div className={isLGScreen ? classes.main : classes.mainMD} id='main'>
          {showUserAlert && (
            <PageAlert
              onClose={onCloseUserAlert}
              data-test='alert-userChanged'
              text='Success! Your details have been updated.'
            />
          )}
          {showPasswordAlert && (
            <PageAlert
              onClose={onClosePasswordAlert}
              data-test='alert-passwordChanged'
              text='Password changed succesfully!'
            />
          )}
          <Grid container spacing={3}>
            <Grid item lg={7} md={8}>
              <Typography
                variant='h1'
                data-test='accountSettingsHeader'
              >
                Account settings
              </Typography>
              <Typography className={classes.description}>
                Edit your details.
              </Typography>
              <AccountForm
                openDialog={handleDialogOpen}
                showUserAlert={setShowUserAlert}
              />
            </Grid>
            <Grid item xs={1} />
            <Grid item lg={4} md={2}>
              <div className={classes.rightColumn}>
                <img src='/img/account-settings.png' alt='' />
              </div>
            </Grid>
            <Grid item md={1} style={{ display: isLGScreen ? 'none' : '' }} />
          </Grid>
        </div>
        <PasswordDialog
          open={isPasswordDialogOpen}
          handleClose={handleDialogClose}
          formRegister={register}
          formWatch={watch}
          formReset={reset}
          showPasswordAlert={setShowPasswordAlert}
          data-test='modal-changePassword'
        />
      </Grid>
      {/* </Grid> */}
    </div>
  )
}

export default AccountPage
