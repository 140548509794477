import React from 'react'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'
import { pointsToNextStage } from '../../helpers/utils'
import {
  Dropdown,
  DashboardTakeaway,
  DashboardReading,
  LinearScoreCard,
  CircularScoreCard
} from '../../components'
import { useStyles } from './style'

const readings = [
  {
    title: 'Professionalising learning and development',
    text:
      'The business landscapes is more volatile than ever. Is your organisation primed to adapt to the relentless pace of change?',
    color: '#FF8200',
    img: '/img/readings/professionialising learning-and-development.png',
    link: 12
  },
  {
    title: 'How to build and change habits in the workplace',
    text:
      'Adapt the working environment to increase efficiency, shape behavioural change - and effect genuine, long-lasting change.',
    color: '#B7312C',
    img: '/img/readings/how-to-build-and-change-habits-in-the-workplace.png',
    link: 8
  },
  {
    title: 'Driving performance and productivity',
    text:
      'By leveraging data insights, you can enhance your organisation\'s learning experience, and implement a strategy that\'s both responsive and impactful.',
    color: '#FF8200',
    img: '/img/readings/driving-performance-and-productivity.png',
    link: 4
  }
]

const takeaways = [
  {
    type: 'benchmark',
    score: 1.8,
    text: 'the average benchmark for this year'
  },
  {
    type: 'industry',
    score: 3.6,
    text: 'the average for organisations in your industry'
  },
  {
    type: 'size',
    score: -2.5,
    text: 'the average for your organisation size'
  },
  {
    type: 'maturity',
    score: 0.96,
    text:
      'more points to move up to the next stage of organisational learning maturity'
  }
]

const DashboardScore = ({ score, profile }) => {
  const classes = useStyles()
  const currentSeason = score[0].values[0].date
  const tooltipText = 'Your score is an aggregate of 96 strategic behaviours, measured on a scale of 1–100. It tracks the overall health of your organisation`s learning strategy, and establishes a baseline for the coming year. In this way, your score indicates not only the impact of your L&D strategy in practice, but also the extent of your digital innovation, and how well your organisation is embedding behavioural change. The learning maturity score has a positive relationship to the four strategic levers of business: 1. Growth, 2. Transformation, 3. Productivity, 4. Profitability.'
  const matchesXl = useMediaQuery((theme) => theme.breakpoints.up('xl'))
  takeaways.forEach(takeaway => {
    const userScore = score[0].values[0].score
    if (takeaway.type === 'benchmark') {
      const benchmarkScore = score[1].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - benchmarkScore).toFixed(2)
    }
    if (takeaway.type === 'industry') {
      const industryScore = score[2].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - industryScore).toFixed(2)
    }
    if (takeaway.type === 'size') {
      const sizeScore = score[3].values.find(value => value.date === currentSeason).score
      takeaway.score = (userScore - sizeScore).toFixed(2)
    }
    if (takeaway.type === 'maturity') {
      takeaway.score = pointsToNextStage(userScore).toFixed(2)
    }
  })

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item sm={8}>
        <Typography variant='h1'>
          Your learning maturity score
        </Typography>
        <Typography className={classes.introText}>
          Let's delve into the statistics behind your overall score, and explore
          how you compare to other organisations that have participated over the
          last few years.
        </Typography>

        <Dropdown
          dropdown='How is my score calculated?'
          text={tooltipText}
        />

      </Grid>
      <Grid item md={1} lg={1} style={{ display: matchesXl ? 'none' : '' }} />
      <Grid item md={3} lg={3} xl={4}>

        <div className={classes.img}>
          <img src='/img/dashboard-summary.png' alt='' />
        </div>
      </Grid>
      <Grid item md={4} lg={4} className={classes.chartsContainer}>
        <CircularScoreCard
          score={score}
          maxScore={100}
          industry={profile.industry}
        />
      </Grid>
      <Grid item md={8} lg={8} className={classes.chartsContainer}>
        <LinearScoreCard type='overall' scores={score} profile={profile} />
      </Grid>
      <Grid item xs={12} classes={{ root: classes.takeaways }}>
        <Typography variant='body1' className={classes.takeawaysTitle}>Quick takeaways</Typography>
        <Typography variant='body1'>
          Remember, your score is measured on a scale of 1–100, and is revisited
          annually to ensure statistical validity and reliability.
        </Typography>
        <Grid alignItems='center' container spacing={2}>
          {takeaways.map((takeaway, idx) => (
            <Grid item xs={12} md={3} lg={3} key={idx}>
              <DashboardTakeaway
                score={takeaway.score}
                text={takeaway.text}
                type={takeaway.type}
                idx={idx}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h2'>
          Recommended reading to improve your score
        </Typography>
        <Grid container spacing={3}>
          {readings.map((reading, idx) => (
            <Grid item xs={12} sm={12} md={4} lg={4} key={idx}>
              <DashboardReading
                title={reading.title}
                text={reading.text}
                idx={idx}
                image={reading.img}
                color={reading.color}
                link={reading.link}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DashboardScore
