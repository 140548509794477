import React from 'react'
import { LinearChart } from '../../components'
import { useStyles } from './style'
import { Paper, Typography, FormGroup, FormControlLabel, Checkbox, Radio } from '@material-ui/core'

const ChartFilters = ({ state, handleChange, profile, forPdf = false }) => {
  const classes = useStyles()
  const filters = ['individual', 'overall', 'industry', 'size', 'region']
  const labels = ['Your score', 'Benchmark', `Your industry (${profile.industry})`, `Your organisation size (${profile.size.replace(/(\s-\s)+/g, '–')})`, `Your region (${profile.region.replace(/(\s\/\s)+/g, '/')})`]

  return (
    <FormGroup row classes={forPdf ? classes.pdf : ''}>
      {filters.map((filter, idx) => (
        <FormControlLabel
          className={forPdf ? classes.pdfLabel : ''}
          key={idx}
          control={
            forPdf
              ? <Radio
                  classes={{ root: classes[filter] }}
                  checked={state[filter]}
                  onChange={idx > 0 ? handleChange : null}
                  name={filter}
                />
              : <Checkbox
                  classes={{ root: classes[filter] }}
                  checked={state[filter]}
                  onChange={idx > 0 ? handleChange : null}
                  name={filter}
                />
          }
          label={labels[idx]}
        />
      ))}
    </FormGroup>
  )
}

const LinerScoreCard = ({ type, scores, profile, forPdf = false }) => {
  const classes = useStyles()

  const [state, setState] = React.useState(!forPdf
    ? {
        individual: true,
        overall: true,
        industry: true,
        size: false,
        region: false
      }
    : {
        individual: true,
        overall: true,
        industry: true,
        size: true,
        region: true
      })
  const filters = Object.keys(state).filter(key => state[key])

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  return (
    <Paper variant='outlined' className={forPdf ? classes.rootForPdf : classes.root}>
      {!forPdf && <Typography variant='h3'>Overall participant trends</Typography>}
      <LinearChart
        forPdf={forPdf}
        type={type}
        filters={filters}
        scores={scores}
      />

      <ChartFilters
        forPdf={forPdf}
        state={state}
        handleChange={handleChange}
        profile={profile}
      />
    </Paper>
  )
}

export default LinerScoreCard
