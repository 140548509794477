import { createSlice } from '@reduxjs/toolkit'
import reducerName from './name'

// Store
export const login = createSlice({
  name: reducerName,
  initialState: {
    isVerified: false,
    showRegisterAlert: false,
    showVerifyAlert: false,
    showVerifyError: false,
    forgotPasswordUsername: ''
  },
  reducers: {
    setIsVerified: (state, { payload }) => {
      state.isVerified = payload
    },
    setShowRegisterAlert: (state, { payload }) => {
      state.showRegisterAlert = payload
    },
    setShowVerifyAlert: (state, { payload }) => {
      state.showVerifyAlert = payload
    },
    setForgotPasswordUsername: (state, { payload }) => {
      state.forgotPasswordUsername = payload
    },
    setShowVerifyError: (state, { payload }) => {
      state.showVerifyError = payload
    }
  }
})

// Reducer Actions - reducer actions are defined inside reducers prop above
export const {
  setIsVerified,
  setShowRegisterAlert,
  setShowVerifyAlert,
  setForgotPasswordUsername,
  setShowVerifyError
} = login.actions

// Reducer
export default login.reducer
