import React from 'react'
import { useStyles } from './style'
import { Paper, Typography } from '@material-ui/core'
import { CircularChart } from '../../components'

const CircularScoreCard = ({ title, score, maxScore, industry }) => {
  const classes = useStyles()
  const currentSeason = score[0].values[0].date
  return (
    <Paper
      variant='outlined'
      className={classes.root}
      data-test='card-yourScore'
    >
      <Typography variant='h3'>
        {title ? `Your ${currentSeason} ${title} score` : `Your ${currentSeason} score`}
      </Typography>
      <CircularChart
        currentSeason={currentSeason}
        scores={score}
        maxScore={maxScore}
        industry={industry}
      />
    </Paper>
  )
}

export default CircularScoreCard
