import name from './name'

const selectSliceData = state => state[name]

const selectIsVerified = state => selectSliceData(state).isVerified
const selectShowRegisterAlert = state => selectSliceData(state).showRegisterAlert
const selectShowVerifyAlert = state => selectSliceData(state).showVerifyAlert
const selectShowVerifyError = state => selectSliceData(state).showVerifyError
const selectForgotPasswordUsername = state => selectSliceData(state).forgotPasswordUsername

export {
  selectIsVerified,
  selectShowRegisterAlert,
  selectShowVerifyAlert,
  selectShowVerifyError,
  selectForgotPasswordUsername
}
