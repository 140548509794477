import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '24px',
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .MuiFormControlLabel-label': {
      marginRight: '30px'
    }
  },
  rootForPdf: {
    padding: '5px',
    minHeight: '600px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '1100px',
    '& .MuiFormControlLabel-label': {
      marginRight: '30px'
    },
    '& h1': {
      marginBottom: 0
    },
    border: 'none !important'
  },
  forPdf: {
    display: 'flex',
    width: '1000px',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'center'
  },
  pdfLabel: {
    fontSize: 20,
    '& p': {
      fontSize: 20
    },
    '& span': {
      fontSize: 20
    }
  },

  individual: {
    color: '#ff8200 !important'
  },
  overall: {
    color: '#006FB7 !important'
  },
  industry: {
    color: '#00A9e0 !important'
  },
  size: {
    color: '#b7312c !important'
  },
  region: {
    color: '#570172 !important'
  }
}))
