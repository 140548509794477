import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/styles'
import { createTheme } from './theme'
import Routes from './routes'
import { AuthProvider } from '@emerald-works/react-auth'
import { EventBusProvider } from '@emerald-works/react-event-bus-client'
import * as DomainHelpers from './helpers/domain'
import config from './amplify-config'
import { surveySlice, userSlice } from './reducers'
import { useNavigate } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import { ConnectionAlert, ResponsiveCheck, IFrameControl } from '../src/components'

const LoadingComponent = () => <h1>loading...</h1>

const eventBusAuthTokenConfig = {
  generate: true,
  apiGatewayAddress: process.env.REACT_APP_API_GATEWAY_ADDRESS,
  awsRegion: process.env.REACT_APP_API_GATEWAY_REGION,
  generateTokenEndpoint: '/generate-token'
}

// here app catches the suspense from page in case translations are not yet loaded
const App = () => {
  const baseUrl = DomainHelpers.basedURL()
  const cookieUrl = DomainHelpers.nakedBasedURL()

  const navigate = useNavigate()

  const handleAuthRedirection = () => {
    navigate('/login')
  }

  const authSettings = {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    cookieStorage: {
      domain: cookieUrl,
      path: '/',
      expires: 365,
      secure: true
    },
    oauth: {
      domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
      scope: [
        'phone',
        'email',
        'profile',
        'openid'
      ],
      redirectSignIn: `https://${baseUrl}/signin`,
      redirectSignOut: `https://${baseUrl}/signout`,
      responseType: 'token'
    }
  }

  const trackEventBusState = (eventName, data) => {
    datadogRum.addAction('event_bus_event', {
      eventName,
      data
    })
    console.log(`Connection ${eventName} hook with state: ${data}`)
  }

  return (
    <>
      <AuthProvider
        amplifyAuthConfig={authSettings}
        onAuthNeededRedirection={handleAuthRedirection}
        allowGuestUsers
        eventBusAuthTokenConfig={eventBusAuthTokenConfig}
      >
        <EventBusProvider
          eventBusURL={process.env.REACT_APP_EVENT_BUS_URL}
          useAuthProvider
          initialisers={[surveySlice.eventBus.retrieveUserResponses, surveySlice.eventBus.getResults, userSlice.eventBus.getUserData, userSlice.eventBus.getFeatureToggles]}
          options={{ triggerTimeout: 10000, timeout: 10e3, maxAttempts: Number.POSITIVE_INFINITY }}
          namespace='benchmark'
          waitForConnection={false}
          LoadingComponent={<LoadingComponent />}
          onConnectionChange={status => trackEventBusState('onConnectionChange', status)}
          onOpen={data => trackEventBusState('onOpen', data)}
          onClose={data => trackEventBusState('onClose', data)}
          onReconnect={data => trackEventBusState('onReconnect', data)}
          onMaximum={data => trackEventBusState('onMaximum', data)}
          onError={data => trackEventBusState('onError', data)}
        >
          <ThemeProvider theme={createTheme()}>
            <CssBaseline />
            <Routes />
          </ThemeProvider>
          <ConnectionAlert />
          <ResponsiveCheck />
          <IFrameControl />
        </EventBusProvider>
      </AuthProvider>
    </>
  )
}

export default App
