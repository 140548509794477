const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const validatePassword = password => {
  // eslint-disable-next-line no-useless-escape
  const rep = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*\W).{8,20}/
  return rep.test(password)
}

const enableRegisterSend = (name, email, password) => {
  if (!name.length) return true
  if (!email.length) return true
  if (password.length < 8) return true
  return false
}

const enableLoginSend = (username, password) => {
  if (password.length < 8) return true
  if (!username.length) return true
  return false
}

const enableForgotPasswordSend = username => {
  return !validateEmail(username)
}

const enableConfirmPasswordSend = (password, confirmPassword, code) => {
  if (password.length < 8) return true
  if (confirmPassword.length < 8) return true
  if (code.length < 6) return true
  return false
}

export {
  validateEmail,
  validatePassword,
  enableRegisterSend,
  enableLoginSend,
  enableForgotPasswordSend,
  enableConfirmPasswordSend
}
