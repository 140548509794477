import React from 'react'
import { Page } from '@react-pdf/renderer'
import { KeyComponents } from '../../components'
// import styles from './style'
const ImpactPage = ({ scores, behaviours }) => {
  const questions = ['Is your organisation fostering a culture of continual improvement?', 'How is your L&D strategy delivering on the 4 strategic levers of business (growth, transformation, productivity and profitability)?']
  const title = 'Learning impact'
  const introText = 'Shared knowledge of the impact that learning is having on performance drives the learning culture within your organisation. Gathering feedback from stakeholders, measuring effectiveness through analytics and benchmarking, and communicating successes with managers and supervisors are vital paths to understanding this impact.'
  const userScore = scores[0].values[0].score
  return (
    <Page size='A4' orientation='landscape' wrap={false}>
      <KeyComponents score={userScore} pageNumber={8} title={title} introText={introText} component='improve' keyQuestions={questions} keyBehaviours={behaviours} />
    </Page>
  )
}
export default ImpactPage
