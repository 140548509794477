import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  stepperRoot: {
    background: '#EEEFF1',
    overflow: 'hidden',
    paddingTop: '0px !important',
    paddingLeft: '8px !important',
    paddingRight: '20px !important'
  },
  drawer: {
    width: '296px',
    zIndex: '9 !important'
  },
  paperHide: {
    left: '72px',
    width: '16px',
    transition: 'width 0.25s ease-in-out',
    backgroundColor: '#EEEFF1'
  },
  paper: {
    width: '296px',
    transition: 'width 0.25s ease-in-out',
    backgroundColor: '#EEEFF1'
  },
  bigDrawer: {
    width: '375px',
    transition: 'width 0.25s ease-in-out',
    zIndex: '9 !important'
  },
  bigPaper: {
    width: '296px',
    transition: 'width 0.25s ease-in-out',
    backgroundColor: '#EEEFF1'
  },
  toolbar: {
    minHeight: '64px'

  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    whiteSpace: 'nowrap'
  },
  item: {
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '14px',
    cursor: 'pointer'

  },
  activeItem: {
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '14px',
    cursor: 'pointer'
  },
  stepLabelContainer: {
    whiteSpace: 'nowrap'
    // width: '300px'
  },
  stepLabelLeftGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'left'
  },
  stepLabelRightGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  stepLabel: {
    fontWeight: 400,
    color: '#253746',
    cursor: 'pointer',
    width: '186px',
    whiteSpace: 'no-wrap'
  },
  activeStepLabel: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    width: '186px',
    whiteSpace: 'no-wrap'
  },
  completeStepLabel: {
    fontWeight: 400,
    color: 'rgba(37, 55, 70)',
    cursor: 'pointer',
    width: '186px',
    whiteSpace: 'no-wrap'
  },
  activeStepChip: {
    color: 'white',
    backgroundColor: theme.palette.secondary.main
  },
  activeStepIcon: {
    color: theme.palette.secondary.main
  },
  innerStepLabel: {
    cursor: 'pointer',
    padding: '2px 0',
    fontSize: '12px'
  },
  activeInnerStepLabel: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: '12px',
    padding: '2px 0'
  },
  linksContainer: {

  },

  linksText: {
    margin: '8px 0',
    paddingLeft: '8px',
    paddingRight: '50px',
    paddingBottom: '4px',
    paddingTop: '4px',
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(52, 51, 55, 0.04)'
    }
  },
  linksDisabled: {
    margin: '8px 0',
    paddingLeft: '8px',
    paddingRight: '50px',
    paddingBottom: '4px',
    paddingTop: '4px',
    color: 'rgba(37, 55, 70, 0.38)',
    cursor: 'pointer',
    borderRadius: '4px'
  },
  innerLinksText: {
    padding: '8px',
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: 'rgba(52, 51, 55, 0.04)'
    }
  },
  innerLinksTextActive: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    padding: '8px',
    cursor: 'pointer'

  },
  linksTextActive: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    margin: '8px 0',
    paddingLeft: '8px',
    paddingRight: '50px',
    paddingBottom: '4px',
    paddingTop: '4px',
    cursor: 'pointer',
    borderRadius: '4px'
  },
  activeShadow: {
    boxShadow: 'inset 0px 0px 5px 200px rgba(0,111,183,0.12), 0px 0px 0px 2px rgba(0,111,183,0.12)'
  },
  innerLinksContainer: {
    marginLeft: '16px'
  },

  dropdownArrow: {
    position: 'relative',
    top: 8,
    display: 'inline-block',
    marginTop: '-20px'
  },

  percentageBlock: {
    width: '80%',
    bottom: '50px',
    margin: '0rem 1.25rem 0',
    display: 'flex',
    alignItems: 'center',
    '@media (min-height: 840px)': {
      position: 'absolute'
    }
  },

  percentage: {
    width: '45%',
    marginRight: '12px'
  },
  linearDeterminateBar: {
    backgroundColor: '#FF8200'
  },
  linearDeterminateBackground: {
    backgroundColor: '#D3D8DE'
  },
  ewLogo: {
    padding: '1rem 1rem'
  },
  ewLogoOnSurvey: {
    position: 'absolute',
    paddingTop: 62
  },
  boxGrow: {
    flexGrow: 1
  },
  overline: {
    fontFamily: 'Matter !important',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '10px !important',
    lineHeight: '16px !important',
    letterSpacing: '1.5px',
    textTransform: 'uppercase'
  },
  overlineMargin: {
    margin: '16px 0',
    marginLeft: '8px'
  },
  activeIcon: {
    '& div': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  completedIcon: {
    width: 24,
    height: 24,
    marginTop: '3px'
  },
  completedIconActive: {
    width: 24,
    height: 24,
    color: theme.palette.secondary.main,
    marginTop: '3px'
  },
  errorIcon: {
    width: 28,
    height: 28,
    marginTop: '3px',
    marginLeft: '-2px'
  },
  errorIconActive: {
    width: 28,
    height: 28,
    color: theme.palette.secondary.main,
    marginTop: '3px',
    marginLeft: '-2px'
  },
  numberIcon: {
    width: 24,
    height: 24,
    backgroundColor: 'transparent !important',
    color: '#253746 !important',
    border: '2px solid #253746 !important',
    marginTop: '3px'
  },
  intro: {
    fontSize: 20,
    lineHeight: '24px',
    fontFamily: 'Matter',
    fontWeight: 400,
    letterSpacing: '0.15px',
    marginTop: '8px',
    marginBottom: '16px',
    marginLeft: '8px'
  }

}))
