import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { surveySlice, userSlice } from '../../reducers'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import {
  AppToolbar,
  NavigationDrawer,
  ReportCard,
  ProgressCard,
  CurrentScoreCard,
  HomePageHeader,
  HomePageLoading,
  HomePageLoadResults,
  HomePageSpinner
} from '../../components'
import { Grid, IconButton } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { useSession } from '@emerald-works/react-auth'
import { useStyles } from './style'
import { getStageByScore, stagesCopy } from '../../helpers/utils'
// import {useFlag} from '@unleash/proxy-client-react'
const retrieveDescription = type => {
  switch (type) {
    case 'new':
      return 'As this is your first time here, why not start by completing your review? Or if you like, feel free to read some case studies, or check out our latest research report.'
    case 'new-season':
      return 'It’s that time of the year again. Set aside a little time to review the last 12 months of your L&D progress. Generate fresh insights and discover actionable solutions for enhancing your entire learning strategy. '
    case 'partial':
      return 'You still have a few questions left to complete before you can access your personalised results. You can go ahead and pick up where you left off.'
    default:
      return 'Welcome back. It’s good to see you again.'
  }
}

const HomePage = () => {
  const classes = useStyles()
  const session = useSession()
  const [retrieveUserResponses] = useEvent([surveySlice.eventBus.retrieveUserResponses])
  const [getResults] = useEvent([surveySlice.eventBus.getResults])
  const [getFeatureToggles] = useEvent([userSlice.eventBus.getFeatureToggles])
  const [getUserData] = useEvent([userSlice.eventBus.getUserData])
  const [getReportUrl] = useEvent([surveySlice.eventBus.getReportUrl])
  const userResults = useSelector(surveySlice.selectors.selectResults)
  const latestResults = useSelector(surveySlice.selectors.selectLatestResults)
  const resultsLoading = useSelector(surveySlice.selectors.selectResultsLoading)
  const reportUrl = useSelector(surveySlice.selectors.selectReportUrl)
  const surveyResponses = useSelector(surveySlice.selectors.selectResponses)
  const { user } = session
  const userStage = latestResults ? getStageByScore(latestResults.scores.score) : 1

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)
  // temp
  const isXLScreen = isLGScreen

  const [showNavBar, setShowNavBar] = React.useState(isLGScreen)
  const [showFloatBtn, setShowFloatBtn] = React.useState(false)
  const [hideNav, setHideNav] = React.useState(isXLScreen)
  const [ovdHideNav, setOvdHideNav] = React.useState(isXLScreen)

  const openNavBar = () => {
    setShowNavBar(true)
  }
  const closeNavBar = () => {
    setShowNavBar(false)
  }

  const handleNavDrawerHover = () => {
    if (!hideNav) {
      setShowFloatBtn(true)
    } else {
      setOvdHideNav(true)
    }
  }

  const handleContentHover = () => {
    setShowFloatBtn(false)
    setOvdHideNav(false)
  }

  const toggleHideNav = () => {
    setHideNav(!hideNav)
    setShowFloatBtn(true)
  }
  const hasPreviousResults = latestResults && latestResults.season === 2021
  const testUserNotStarted = surveyResponses && Object.keys(surveyResponses).length === 0
  const testUserCompleted = userResults && userResults.score
  const testUserNewSeason = hasPreviousResults

  const userName = user?.attributes.name.split(' ')[0]
  const userReviewNotStarted = testUserNotStarted
  const userReviewCompleted = testUserCompleted
  const userReviewNewSeason = testUserNewSeason
  const pageState = userReviewNotStarted && !userReviewNewSeason
    ? 'new'
    : userReviewNewSeason && !userReviewCompleted
      ? 'new-season'
      : userReviewCompleted
        ? 'recurrent'
        : 'partial'
  const pageDescription = retrieveDescription(pageState)

  useEffect(() => {
    if (isLGScreen) setShowNavBar(true)
    if (isMDScreen) setShowNavBar(false)
  }, [isLGScreen, isMDScreen])

  useEventsOnViewLoad(() => {
    getResults.trigger()
    retrieveUserResponses.trigger()
    getUserData.trigger()
    getFeatureToggles.trigger()
    getReportUrl.trigger()
  }, [getFeatureToggles, getResults, retrieveUserResponses, getUserData, getReportUrl])

  // eslint-disable-next-line react/jsx-closing-tag-location
  return resultsLoading
    ? (
      <HomePageSpinner />
      )
    : (
      <div className={classes.root}>
        <AppToolbar
          isMobileScreen={isMDScreen || isXSScreen}
          navBarOpen={showNavBar}
          openNavBar={openNavBar}
          closeNavBar={closeNavBar}
        />
        <Grid container>
          <Grid container item lg={2}>

            <NavigationDrawer
              isLoading={resultsLoading}
              isLGScreen={isLGScreen}
              showNavBar={showNavBar}
              closeNavBar={closeNavBar}
              hideNav={hideNav}
              ovdHideNav={ovdHideNav}
              onMouseOver={handleNavDrawerHover}
            />
            {((isLGScreen && showFloatBtn) || (isLGScreen && hideNav)) && !isXLScreen && (
              <IconButton
                aria-label='toggle navigation menu'
                className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? classes.collapseBtn : classes.collapseBtnHide}
                onClick={toggleHideNav}
              >
                {
                  !hideNav
                    ? (
                      <KeyboardArrowLeft
                        className={classes.collapseIcon}
                      />
                      )
                    : (
                      <KeyboardArrowRight
                        className={classes.collapseIcon}
                      />
                      )
                }
              </IconButton>
            )}
          </Grid>
          <Grid container>
            <div
              className={isXLScreen ? `${classes.mainMD} ${classes.mainXL}` : isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? classes.main : classes.mainMD}
              onMouseOver={handleContentHover}
              id='main'
            >
              <Grid container spacing={3} className={classes.mainGrid}>
                {
                  resultsLoading
                    ? (
                      <HomePageLoading />
                      )
                    : (
                      <HomePageHeader
                        userName={userName}
                        pageDescription={pageDescription}
                        pageState={pageState}
                        hasPreviousResults={hasPreviousResults}
                      />
                      )
                }
                <Grid item md={1} lg={1} />
                <Grid item xs={12} md={4} lg={4}>
                  <div className={classes.imgContainer}>
                    <img
                      className={classes.img}
                      src='/img/home.png'
                      alt=''
                    />
                  </div>
                </Grid>
                <Grid item style={{ display: !isLGScreen ? 'none' : '' }} lg={1} />
                <Grid item md={12} lg={6} xl={6}>
                  {/* TYPE ARTICLE */}
                  {
                    resultsLoading
                      ? (
                        <HomePageLoadResults />
                        )
                      : pageState !== 'recurrent' && !latestResults
                        ? (
                          // <CaseStudyCard />
                          <ProgressCard />
                          )
                        : (
                          <CurrentScoreCard
                            stagesCopy={stagesCopy}
                            userStage={userStage}
                            latestResults={latestResults}
                          />

                          )
                  }
                </Grid>
                <Grid item md={12} lg={6} xl={6}>
                  {/* TYPE DOWNLOAD */}
                  <ReportCard reportUrl={reportUrl} />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      )
}

export default HomePage
