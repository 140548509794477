import React, { useEffect } from 'react'
import SwipeableViews from 'react-swipeable-views'
import MobileStepper from '@material-ui/core/MobileStepper'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import carouselSteps from './steps'
import { useStyles } from './style'

const RegisterCarousel = () => {
  const [activeStep, setActiveStep] = React.useState(0)
  const maxSteps = carouselSteps.length
  const { carouselStep, stepTitle, root } = useStyles()

  const handleNext = () => {
    setActiveStep(prevActiveStep => {
      if (prevActiveStep === 3) return 0
      return prevActiveStep + 1
    })
  }

  const handlePrev = () => {
    setActiveStep(prevActiveStep => {
      if (prevActiveStep === 0) return 3
      return prevActiveStep - 1
    })
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }

  useEffect(() => {
    setInterval(handleNext, 15000)
    return () => {}
  }, [])

  return (
    <Grid container justify='center' alignItems='center' className={root}>
      <Grid item xs={12}>
        <SwipeableViews
          axis='x'
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {carouselSteps.map((step, index) => (
            <div key={index} className={carouselStep}>
              <img
                data-test={`carouselImg-${index}`}
                src={step.img}
                alt=''
              />
              <Typography
                data-test={`carouselTitle-${index}`}
                variant='h2'
                className={stepTitle}
              >
                {step.title}
              </Typography>
              {step.data ? step.data() : null}
            </div>
          ))}
        </SwipeableViews>
        <MobileStepper
          style={{ justifyContent: 'center', backgroundColor: '#EEEFF1' }}
          steps={maxSteps}
          position='static'
          variant='dots'
          activeStep={activeStep}
          nextButton={
            <Button data-test='carouselNext' size='small' onClick={handleNext} aria-label='Carousel next button'>
              <KeyboardArrowRight />
            </Button>
          }
          backButton={
            <Button data-test='carouselBack' size='small' onClick={handlePrev} aria-label='Carousel back button'>
              <KeyboardArrowLeft />
            </Button>
          }
        />
      </Grid>
    </Grid>
  )
}

export default RegisterCarousel
