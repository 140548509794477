import * as actions from './slice'

export const createUserData = {
  eventName: 'createUserData',
  onSuccess: [{ redux: true, action: actions.setUserData }],
  onError: [{ redux: true, action: actions.createUserDataError }]
}

export const getUserData = {
  eventName: 'getUserData',
  onSuccess: [{ redux: true, action: actions.setUserData }],
  onError: [{ redux: true, action: actions.getUserDataError }]
}

export const sendContactEmail = {
  eventName: 'sendContactEmail',
  onSuccess: [],
  onError: [{ redux: true, action: actions.sendContactEmailError }]
}

export const updateUserDataOnRegister = {
  eventName: 'updateUserDataOnRegister',
  onSuccess: [],
  onError: [{ redux: true, action: actions.updateUserDataOnRegisterError }]
}

export const getFeatureToggles = {
  eventName: 'getFeatureToggles',
  onSuccess: [{ redux: true, action: actions.setFeatureToggles }],
  onError: []
}
