import React from 'react'
import DashboardBehaviourCard from './card'
import { useStyles } from './style'
import { Grid, Typography, useMediaQuery } from '@material-ui/core'

const DashboardBehaviourSection = ({ behaviours, season }) => {
  const classes = useStyles()
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  return (
    <Grid container spacing={3} classes={{ root: classes.root }}>
      <Grid item md={7} lg={9}>
        <Typography variant='h2' color='inherit' data-test='card-keyBehaviours'>
          Key behaviours to be proud of
        </Typography>
      </Grid>
      <Grid item md={2} style={{ display: matchesLg ? 'none' : '' }} />
      <Grid item md={2} lg={3}>
        <img src='/img/dashboard-behaviours.png' alt='' />
      </Grid>
      <Grid item xs={12} lg={2}>
        <Typography variant='body1' color='inherit'>
          Compared to the average for {season}, you're doing great in the following
          areas:
        </Typography>
      </Grid>
      {behaviours.map((behaviour, idx) => (
        <Grid key={idx} item xs={12} md={6} lg={5}>
          <DashboardBehaviourCard
            score={behaviour.user}
            question={behaviour.text}
            average={behaviour.benchmark}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default DashboardBehaviourSection
