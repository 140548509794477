import React, { useLayoutEffect, useRef } from 'react'
import { PercentagesChart, ChartStage, CircularChart, LinearScoreCard, DashboardTrendPanel } from '../index'
import { toPng } from 'html-to-image'
import { useDispatch } from 'react-redux'
import { dashboard } from '../../reducers/dashboard/slice'
const GraphsForPDF = ({ componentsChartData, scoresChartData, stage, profile, currentSeason, ...responses }) => {
  // for tables
  const tabs = [
    {
      url: 'Technology',
      label: 'Technology',
      type: 'tech',
      caption: 'Top five trending technologies',
      trends: {
        2021: [
          {
            text: 'Virtual meetings (e.g. Skype, Google Hangouts, Zoom)',
            used: true,
            percentage: 82,
            change: 3
          },
          {
            text: 'Job aids (e.g. pdf checklists, infographics)',
            used: false,
            percentage: 75,
            change: -4
          },
          {
            text: 'Webinars (e.g. GoToWebinar, Webex, Livestream, Adobe Connect)',
            used: false,
            percentage: 74,
            change: 6
          },
          {
            text: 'Communication tools (chat, IM, SMS, newsletter, forum)',
            used: true,
            percentage: 74,
            change: -3
          },
          {
            text: 'Mobile devices (e.g. smartphones, tablets)',
            used: false,
            percentage: 68,
            change: -1
          }
        ],
        2022: [
          {
            text: 'Communication tools (e.g., instant messaging, forums)',
            used: true,
            percentage: 80,
            change: 5
          },
          {
            text: 'Virtual classrooms (live)',
            used: false,
            percentage: 78,
            change: 24
          },
          {
            text: 'Webinar (e.g., Webex, Adobe Connect)',
            used: false,
            percentage: 74,
            change: 0
          },
          {
            text: 'Job aids (e.g., PDF checklists, infographics)',
            used: true,
            percentage: 72,
            change: -3
          },
          {
            text: 'Mobile devices (smartphones, tablets)',
            used: false,
            percentage: 69,
            change: 1
          }
        ]
      }
    },
    {
      url: 'Challenges',
      label: 'Challenges',
      type: 'challenges',
      caption: 'Top five top challenges',
      trends: {
        2021: [
          {
            text: 'Reluctance by managers to make time for learning',
            used: true,
            percentage: 44,
            change: -1
          },
          {
            text: 'L&D are overwhelmed and under-equipped',
            used: false,
            percentage: 40,
            change: 1
          },
          {
            text: 'Leaders have traditional expectations of L&D that are difficult to challenge',
            used: false,
            percentage: 35,
            change: -1
          },
          {
            text: 'Lack of investment to future-proof L&D approaches',
            used: true,
            percentage: 33,
            change: -2
          },
          {
            text: 'Cost of set-up, development and maintenance of tech solutions',
            used: false,
            percentage: 31,
            change: -1
          }
        ],
        2022: [
          {
            text: 'Reluctance by managers to make time for learning',
            used: true,
            percentage: 42,
            change: -2
          },
          {
            text: 'Overwhelmed and underequipped',
            used: false,
            percentage: 38,
            change: -2
          },
          {
            text: 'Leaders have traditional expectations about L&D which are difficult to change',
            used: false,
            percentage: 35,
            change: -1
          },
          {
            text: 'Lack of investment in future-proofing our L&D strategy',
            used: true,
            percentage: 31,
            change: -2
          },
          {
            text: 'Lack of analytical skills to use learning data effectively',
            used: false,
            percentage: 30,
            change: 2
          }
        ]
      }
    },
    {
      url: 'Skills',
      label: 'In-house skillsets',
      type: 'skills',
      caption: 'Top five in-house skillsets',
      trends: {
        2022: [
          {
            text: 'In-person classroom delivery',
            used: true,
            percentage: 77,
            change: 0
          },
          {
            text: 'Learning management / administration',
            used: false,
            percentage: 61,
            change: 0
          },
          {
            text: 'Virtual classroom / webinar delivery',
            used: false,
            percentage: 61,
            change: 22
          },
          {
            text: 'Evaluating impact',
            used: true,
            percentage: 57,
            change: 3
          },
          {
            text: 'Crafting learning journeys/blended solutions',
            used: false,
            percentage: 47,
            change: 7
          }
        ],
        2021: [
          {
            text: 'Classroom / F2F learning / training delivery',
            used: true,
            percentage: 77,
            change: -5
          },
          {
            text: 'Learning management / administration',
            used: false,
            percentage: 61,
            change: -5
          },
          {
            text: 'Facilitation and assessment',
            used: false,
            percentage: 54,
            change: -6
          },
          {
            text: 'Programme evaluation',
            used: true,
            percentage: 45,
            change: -10
          },
          {
            text: 'Coaching and mentoring',
            used: false,
            percentage: 44,
            change: -7
          }
        ]
      }
    }
  ]
  tabs.forEach(tab => {
    const tabResponses = responses[tab.type]
    tab.trends[currentSeason].forEach(trend => {
      const trendResponse = tabResponses[trend.text] ? tabResponses[trend.text] : ''
      trend.used = tab.type === 'skills' || tab.type === 'tech'
        ? trendResponse === 'Column 1' || trendResponse === '1' || trendResponse === 'Column 2' || trendResponse === '2'
        : trendResponse === 'Column 1' || trendResponse === '2' || trendResponse === 'Column 2' || trendResponse === '2'
    })
  })

  const dispatch = useDispatch()
  const { setReportImagesLoaded } = dashboard.actions
  const percentagesChartRef = useRef(null)
  const circularChartRef = useRef(null)
  const linearChartRef = useRef(null)
  const stagesChartRef = useRef(null)
  const alignChartRef = useRef(null)
  const enableChartRef = useRef(null)
  const employeeChartRef = useRef(null)
  const engageChartRef = useRef(null)
  const improveChartRef = useRef(null)
  const influenceChartRef = useRef(null)
  const linearStageChartRef = useRef(null)
  const techTrendsRef = useRef(null)
  const challengesTrendsRef = useRef(null)
  const skillsTrendsRef = useRef(null)
  useLayoutEffect(() => {
    const handlePngs = async () => {
      const percentagesChartData = await toPng(percentagesChartRef.current)
      localStorage.setItem('percentagesChartData', percentagesChartData)
      const circularChartData = await toPng(circularChartRef.current)
      localStorage.setItem('circularChartData', circularChartData)
      const linearChartData = await toPng(linearChartRef.current)
      localStorage.setItem('linearChartData', linearChartData)
      const stagesChartData = await toPng(stagesChartRef.current)
      localStorage.setItem('stagesChartData', stagesChartData)
      const alignChartData = await toPng(alignChartRef.current)
      localStorage.setItem('alignChartData', alignChartData)
      const enableChartData = await toPng(enableChartRef.current)
      localStorage.setItem('enableChartData', enableChartData)
      const employeeChartData = await toPng(employeeChartRef.current)
      localStorage.setItem('employeeChartData', employeeChartData)
      const engageChartData = await toPng(engageChartRef.current)
      localStorage.setItem('engageChartData', engageChartData)
      const improveChartData = await toPng(improveChartRef.current)
      localStorage.setItem('improveChartData', improveChartData)
      const influenceChartData = await toPng(influenceChartRef.current)
      localStorage.setItem('influenceChartData', influenceChartData)
      const linearStageChartData = await toPng(linearStageChartRef.current)
      localStorage.setItem('linearStageChartData', linearStageChartData)
      const techTrendsData = await toPng(techTrendsRef.current)
      localStorage.setItem('techTrendsData', techTrendsData)
      const challengesTrendsData = await toPng(challengesTrendsRef.current)
      localStorage.setItem('challengesTrendsData', challengesTrendsData)
      const skillsTrendsData = await toPng(skillsTrendsRef.current)
      localStorage.setItem('skillsTrendsData', skillsTrendsData)
      dispatch(dashboard.actions.setReportImagesLoaded(true))
    }

    if (percentagesChartRef.current && circularChartRef.current && linearChartRef.current && stagesChartRef.current && alignChartRef.current && enableChartRef.current && employeeChartRef.current && engageChartRef.current && improveChartRef.current && influenceChartRef.current && linearStageChartRef.current && techTrendsRef.current && challengesTrendsRef.current) {
      handlePngs()
    }
  }, [dispatch, setReportImagesLoaded])
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '1200px', position: 'relative', top: 600 }}>
      <div ref={percentagesChartRef}>
        <PercentagesChart
          id='report-percentages-chart'
          forPdf
          currentSeason={currentSeason}
        />
      </div>
      <div ref={circularChartRef} style={{ width: 600, height: 600 }}>
        <CircularChart
          animate={false}
          id='report-circular-chart'
          isSummary={false}
          scores={scoresChartData}
          maxScore={100}
          industry={profile.industry}
          handleClick={() => { }}
          currentSeason={currentSeason}
        />
      </div>

      <div ref={stagesChartRef}>

        <ChartStage id='report-stage-chart' stage={stage} expanded title={null} points={null} />
      </div>
      <div ref={linearChartRef}>
        {/* overall chart */}
        <LinearScoreCard profile={profile} id='report-components-align-chart' type='overall' scores={scoresChartData} forPdf />
      </div>
      {/* align chart */}
      <div ref={alignChartRef}>
        <LinearScoreCard profile={profile} type='components' scores={componentsChartData.Align} forPdf />
      </div>
      {/* enable chart */}
      <div ref={enableChartRef}>
        <LinearScoreCard profile={profile} type='components' scores={componentsChartData.Enable} forPdf />
      </div>
      {/* employee chart */}
      <div ref={employeeChartRef}>
        <LinearScoreCard profile={profile} type='components' scores={componentsChartData.Listen} forPdf />
      </div>
      {/* org engagement */}
      <div ref={engageChartRef}>
        <LinearScoreCard profile={profile} type='components' scores={componentsChartData.Engage} forPdf />
      </div>
      {/* learning impact */}
      <div ref={improveChartRef}>
        <LinearScoreCard profile={profile} type='components' scores={componentsChartData.Improve} forPdf />
      </div>
      {/* environment */}
      <div ref={influenceChartRef}>
        <LinearScoreCard profile={profile} type='components' scores={componentsChartData.Influence} forPdf />
      </div>
      {/* stage chart */}
      <div ref={linearStageChartRef}>
        <LinearScoreCard profile={profile} type='stage' scores={scoresChartData} forPdf />
      </div>

      {/* technology trends */}
      <div ref={techTrendsRef} style={{ overflow: 'hidden' }}>
        <DashboardTrendPanel tab='Technology' trends={tabs[0].trends[2022]} season={2022} caption={tabs[0].caption} />
      </div>
      {/* challenges trends */}
      <div ref={challengesTrendsRef} style={{ overflow: 'hidden' }}>
        <DashboardTrendPanel tab='Challenges' trends={tabs[1].trends[2022]} season={2022} caption={tabs[1].caption} />
      </div>
      <div ref={skillsTrendsRef} style={{ overflow: 'hidden' }}>
        <DashboardTrendPanel tab='Skills' trends={tabs[2].trends[2022]} season={2022} caption={tabs[2].caption} />
      </div>
    </div>
  )
}
export default GraphsForPDF
