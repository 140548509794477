import React, { useCallback } from 'react'
import { CircularChart } from '../../components'
import { useStyles } from './style'
import { Paper, Typography, Divider, Button, Icon } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const DashboardSummaryScore = ({ score, setDashboardPage, setInnerDashboardPage, isLoading, industry, latestSeason }) => {
  const classes = useStyles()

  const FirstKeyIcon = () => (
    <Icon className={classes.paperIcon}>
      <img
        src='/img/dashboard-align-icon.svg'
        width='100%'
        height='100%'
        alt=''
      />
    </Icon>
  )

  const SecondKeyIcon = () => (
    <Icon className={classes.paperIcon}>
      <img
        src='/img/dashboard-engage-icon.svg'
        width='100%'
        height='100%'
        alt=''
      />
    </Icon>
  )

  const ThirdKeyIcon = () => (
    <Icon className={classes.paperIcon}>
      <img
        src='/img/dashboard-environment-icon.svg'
        width='100%'
        height='100%'
        alt=''
      />
    </Icon>
  )

  const FourthKeyIcon = () => (
    <Icon className={classes.paperIcon}>
      <img
        src='/img/dashboard-capabilities-icon.svg'
        width='100%'
        height='100%'
        alt=''
      />
    </Icon>
  )

  const FifthKeyIcon = () => (
    <Icon className={classes.paperIcon}>
      <img
        src='/img/dashboard-organisational-icon.svg'
        width='100%'
        height='100%'
        alt=''
      />
    </Icon>
  )

  const SixthKeyIcon = () => (
    <Icon className={classes.paperIcon}>
      <img
        src='/img/dashboard-impact-icon.svg'
        width='100%'
        height='100%'
        alt=''
      />
    </Icon>
  )

  const exploreScore = useCallback(() => {
    setDashboardPage('score')
    setInnerDashboardPage('')
  }, [setDashboardPage, setInnerDashboardPage])

  const handleClick = innerPage => {
    setDashboardPage('areas')
    setInnerDashboardPage(innerPage)
  }

  return (
    <Paper className={classes.paperRoot}>
      <Typography variant='h3'>Your learning maturity score</Typography>
      <div className={classes.chartBox}>
        {
          isLoading
            ? <Skeleton style={{ marginTop: '16px', marginLeft: '10%' }} variant='circle' animation='wave' width='50%' height='100%' />
            : (
              <CircularChart
                isSummary
                scores={score.score}
                error={score.chartDataError}
                maxScore={100}
                industry={industry}
                handleClick={exploreScore}
                currentSeason={latestSeason}
              />
              )
        }
      </div>
      <Divider className={classes.paperDivider} />
      <Typography variant='body1'>
        Your score is made up of six key components:
      </Typography>
      <div className={classes.paperComponents}>
        {
          isLoading
            ? (
              <span>
                <Skeleton animation='wave' width='100%' height='40px' />
                <Skeleton animation='wave' width='100%' height='40px' />
                <Skeleton animation='wave' width='100%' height='40px' />
                <Skeleton animation='wave' width='100%' height='40px' />
                <Skeleton animation='wave' width='100%' height='40px' />
                <Skeleton animation='wave' width='100%' height='40px' />
              </span>
              )
            : (
              <span>
                <Button
                  onClick={() => handleClick('Align')}
                  className={classes.paperButton}
                  fullWidth
                  endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                  startIcon={<FirstKeyIcon />}
                  data-test='toStrategic'
                >
                  Strategy and business alignment
                </Button>
                <Button
                  onClick={() => handleClick('Listen')}
                  className={classes.paperButton}
                  fullWidth
                  endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                  startIcon={<SecondKeyIcon />}
                  data-test='toEmployee'
                >
                  Employee experience
                </Button>
                <Button
                  onClick={() => handleClick('Influence')}
                  className={classes.paperButton}
                  fullWidth
                  endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                  startIcon={<ThirdKeyIcon />}
                  data-test='toEnvironment'
                >
                  Environment, talent and culture
                </Button>
                <Button
                  onClick={() => handleClick('Enable')}
                  className={classes.paperButton}
                  fullWidth
                  endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                  startIcon={<FourthKeyIcon />}
                  data-test='toLD'
                >
                  L&D team capabilities
                </Button>
                <Button
                  onClick={() => handleClick('Engage')}
                  className={classes.paperButton}
                  fullWidth
                  endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                  startIcon={<FifthKeyIcon />}
                  data-test='toOrganisational'
                >
                  Organisational engagement
                </Button>
                <Button
                  onClick={() => handleClick('Improve')}
                  className={classes.paperButton}
                  fullWidth
                  endIcon={<ArrowForwardIosIcon className={classes.arrowIcon} />}
                  startIcon={<SixthKeyIcon />}
                  data-test='toLearning'
                >
                  Learning impact
                </Button>
              </span>
              )
        }
      </div>
    </Paper>
  )
}

export default DashboardSummaryScore
