import React from 'react'
import {
  FormControl,
  TextField,
  Grid,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { useForm } from 'react-hook-form'
import { useStyles } from './style'
import Auth from '@aws-amplify/auth'
import {
  enableConfirmPasswordSend,
  validatePassword
} from '../../libs/formUtils'
import { useNavigate } from 'react-router-dom'
import { LPBLink } from '../../components'
import { loginSlice } from '../../reducers'
import { useSelector } from 'react-redux'

const ChangePasswordForm = ({ showAlert }) => {
  const classes = useStyles()
  const history = useNavigate()
  const forgotPasswordUsername = useSelector(
    loginSlice.selectors.selectForgotPasswordUsername
  )

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
      code: ''
    }
  })

  const currentPassword = watch('password')
  const currentConfirmPassword = watch('confirmPassword')
  const currentCode = watch('code')

  const isDisabled = enableConfirmPasswordSend(
    currentPassword,
    currentConfirmPassword,
    currentCode
  )

  const passwordError = {
    error: !isDisabled && !validatePassword(currentPassword)
  }

  const confirmPasswordError = {
    error: !isDisabled && !validatePassword(currentConfirmPassword)
  }

  if (forgotPasswordUsername === '') history('/forgot-password')

  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const [isUpdating, setIsUpdating] = React.useState(false)
  const [updateFeedback, setUpdateFeedback] = React.useState({})
  const [cognitoFeedback, setCognitoFeedback] = React.useState({})

  const handleShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(prevShowPassword => !prevShowPassword)
  }

  const onSubmit = async data => {
    const { password, confirmPassword } = data
    setIsUpdating(true)
    if (password !== confirmPassword) {
      setUpdateFeedback({ error: true, text: 'Passwords must match.' })
    } else {
      try {
        setUpdateFeedback({})
        setCognitoFeedback({})
        await Auth.forgotPasswordSubmit(
          forgotPasswordUsername,
          currentCode,
          currentPassword
        )
        showAlert(true)
        setTimeout(async () => {
          try {
            await Auth.signIn({
              username: forgotPasswordUsername,
              password
            })
            history('/')
          } catch (e) {
            console.log('signinError: ', e)
          }
        }, 3000)
      } catch (e) {
        setCognitoFeedback({ error: true, text: e.message })
      }
    }
    setIsUpdating(false)
  }

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Typography data-test='authPageHeader' variant='h1'>
          Create a new password
        </Typography>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              data-test='confirmationCodeField'
              label='Enter code'
              type='text'
              variant='outlined'
              name='code'
              required
              error={cognitoFeedback.error}
              helperText={'' || cognitoFeedback.text}
              // eslint-disable-next-line no-useless-escape
              inputRef={register({ required: true })}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              data-test='passwordField'
              label='New password'
              type={showPassword ? 'text' : 'password'}
              variant='outlined'
              name='password'
              required
              error={passwordError.error}
              helperText={
                '8+ characters, with a mix of lower and upper case letters, numbers and symbols.' ||
                passwordError.text
              }
              // eslint-disable-next-line no-useless-escape
              inputRef={register({
                required: true,
                pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*\W).{8,20}/
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      data-test='showPassword'
                      tabIndex='-1'
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              data-test='confirmPasswordField'
              label='Confirm new password'
              type={showConfirmPassword ? 'text' : 'password'}
              variant='outlined'
              name='confirmPassword'
              required
              error={confirmPasswordError.error || updateFeedback.error}
              helperText={'' || updateFeedback.text}
              // eslint-disable-next-line no-useless-escape
              inputRef={register({
                required: true,
                pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*\W).{8,20}/
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      data-test='showConfirmPassword'
                      tabIndex='-1'
                      onClick={handleShowConfirmPassword}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </FormControl>

          {!isUpdating
            ? (
              <Button
                className={classes.primaryButton}
                type='submit'
                variant='contained'
                color='secondary'
                disabled={isDisabled}
                data-test='btn-submit-changePage'
              >
                Submit
              </Button>
              )
            : (<CircularProgress />)}
          <p className={classes.loginText}>
            Go back to{' '}
            <LPBLink data-test='lnk-login-changePasswordPage' to='/login'>
              log in.
            </LPBLink>
          </p>
        </form>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

export default ChangePasswordForm
