import React from 'react'
import {
  DashboardSummaryStage,
  DashboardMaturityExplore,
  LinearScoreCard,
  DashboardMaturityPercentagesCard,
  DashboardMaturirySolutions
} from '../../components'
import { useStyles } from './style'
import { Grid, Typography } from '@material-ui/core'

const DashboardMaturity = ({ stage, scores, profile, resultsSeason }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={8}>
        <Typography variant='h1'>Your learning maturity stage</Typography>
        <Typography className={classes.introText}>
          Our four-phase maturity model will forge your path towards becoming a
          top-performing learning organisation. Explore our recommendations for
          how to progress to the higher stages of the model.
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.img}>
          <img src='/img/dashboard-maturity.png' alt='' />
        </div>
      </Grid>
      <Grid item xs={12}>
        <DashboardSummaryStage stage={stage} expanded />
      </Grid>
      <Grid item md={8} lg={8} className={classes.chartsContainer}>
        <LinearScoreCard type='stage' scores={scores} profile={profile} />
      </Grid>
      <Grid item md={4} lg={4} className={classes.chartsContainer}>
        <DashboardMaturityPercentagesCard season={resultsSeason} />
      </Grid>
      <Grid item xs={12}>
        <DashboardMaturityExplore stage={stage} />
      </Grid>
      <Grid item xs={12}>
        <DashboardMaturirySolutions />
      </Grid>
    </Grid>
  )
}
export default DashboardMaturity
