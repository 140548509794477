import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { useDispatch } from 'react-redux'
import { useStyles } from './style'
import { useNavigate } from 'react-router-dom'
import { registerSlice } from '../../reducers'

const RegisterHeader = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatcher = useDispatch()
  const title = 'Learning\nPerformance\nBenchmark'

  const homeClick = () => {
    dispatcher(registerSlice.actions.setRegisterData({ isRegistered: false }))
    navigate('/')
  }

  return (
    <Grid container direction='row'>
      <Grid item xs>
        <div className={classes.box}>
          <img
            src='img/mindtools-logo.png'
            alt='MindTools for Business Home'
            onClick={homeClick}
            className={classes.image}
          />
          <Typography variant='h3' className={classes.title} onClick={() => navigate('/')}>
            {title.split('\n').map((i, key) => {
              return <p key={key}>{i}</p>
            })}
          </Typography>
        </div>
      </Grid>
    </Grid>
  )
}

export default RegisterHeader
