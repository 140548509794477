import React from 'react'
import {
  Dialog,
  Typography,
  Button
} from '@material-ui/core'
import { useStyles } from './style'
import { useNavigate } from 'react-router-dom'

const WelcomeDialog = ({
  open,
  handleClose
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  const dialogClose = () => {
    handleClose()
  }

  const handleHome = () => {
    navigate('/')
  }

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <img src='/img/welcome.png' alt='Welcome' />
        <Typography variant='h2'>Welcome!</Typography>
        <Typography>Get started by completing your review</Typography>
        <div className={classes.buttons}>
          <Button id='btn-home-welcome' variant='outlined' color='secondary' onClick={handleHome}>Home</Button>
          <Button id='btn-start-welcome' className={classes.rightButton} variant='contained' color='secondary' onClick={dialogClose}>Start Your Review</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default WelcomeDialog
