import React from 'react'
import { ChartStage } from '../../components'
import { useStyles } from './style'
import { Paper, Typography, Button } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { stagesCopy } from '../../helpers/utils'

const DashboardSummaryStage = ({
  stage,
  setDashboardPage,
  setInnerDashboardPage,
  expanded,
  isLoading
}) => {
  const classes = useStyles()

  const stageInfo = stagesCopy[stage]
  const handleClick = () => {
    setDashboardPage('stage')
    setInnerDashboardPage('')
  }

  if (expanded) {
    return (
      <Paper
        className={classes.paperRoot}
        variant={expanded ? 'outlined' : ''}
        data-test='card-youAreAt'
      >
        <Typography className={classes.introText}>You are at:</Typography>
        <Typography variant='h2' data-test='youAreAtStage' className={classes.stageHeading}>
          {stageInfo.head}
        </Typography>
        <div className={classes.points}>
          <ul>
            {stageInfo.points.map((point, idx) => (
              <li key={idx}>
                <Typography variant='body1'>{point}</Typography>
              </li>
            ))}
          </ul>
        </div>
        <div className={classes.bigChartBox}>
          <ChartStage
            stage={stage}
            title={stageInfo.head}
            points={stageInfo.points}
            expanded
          />
        </div>
      </Paper>
    )
  } else {
    return (
      <Paper className={classes.paperRoot}>
        <Typography variant='h3'>Your learning maturity stage</Typography>
        {
          isLoading
            ? <Skeleton style={{ margin: '12px 0' }} animation='wave' height='360px' width='100%' />
            : (
              <>

                <span>
                  <div className={classes.chartBox}>
                    <ChartStage stage={stage} />
                  </div>
                  <div className={classes.textBox}>
                    <Typography variant='subtitle1'>{stageInfo.head}</Typography>

                    <ul className={classes.paperList}>
                      {stageInfo &&
                      stageInfo.points &&
                      stageInfo.points.length &&
                      stageInfo.points.map((info, idx) => (
                        <li key={idx}>
                          <Typography variant='body1'>{info}</Typography>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <Button
                    onClick={handleClick}
                    variant='contained'
                    color='secondary'
                    data-test='btn-Improve'
                  >
                    Discover how you can improve
                  </Button>
                </span>
              </>
              )
        }
      </Paper>
    )
  }
}

export default DashboardSummaryStage
