import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { PrivateRoute } from '@emerald-works/react-auth'
import {
  HomePage,
  AccountPage,
  ContactPage,
  SurveyPage,
  DashboardPage,
  Register,
  Login,
  ForgotPassword,
  ChangePassword,
  VerifyPage,
  PrivacyPolicy,
  TermsAndConditions,
  CookiesPolicy,
  EthicalStandards,
  NotFound,
  PDFPreview
} from './pages'

const ApplicationRoutes = () => {
  return (
    <Routes>
      {/* <Route
        path='/team-create'
        element={
          <TeamCreatePage />
        }
      /> */}
      <Route
        path='/register'
        element={
          <Register />
        }
      />
      <Route
        path='/login'
        element={
          <Login />
        }
      />
      <Route
        path='/forgot-password'
        element={
          <ForgotPassword />
        }
      />
      <Route
        path='/change-password'
        element={
          <ChangePassword />
        }
      />
      <Route
        path='/verify'
        element={
          <VerifyPage />
        }
      />
      <Route
        path='/privacy-policy'
        element={
          <PrivacyPolicy />
        }
      />
      <Route
        path='/terms-and-conditions'
        element={
          <TermsAndConditions />
        }
      />
      <Route
        path='/cookies-policy'
        element={
          <CookiesPolicy />
        }
      />
      <Route
        path='/contact'
        element={
          <ContactPage />
        }
      />
      <Route
        path='/ethical'
        element={
          <EthicalStandards />
        }
      />
      <PrivateRoute
        path='/'
        element={
          <HomePage />
        }
      />
      <PrivateRoute
        path='/account'
        element={
          <AccountPage />
        }
      />
      <PrivateRoute
        path='/review'
        element={
          <SurveyPage />
        }
      />
      <PrivateRoute
        path='/dashboard'
        element={
          <DashboardPage />
        }
      />
      {/* <PrivateRoute
        path='/clean-results'
        element={
          <ResultsCleanPage />
        }
      /> */}
      <PrivateRoute
        path='/pdf'
        element={
          <PDFPreview />
        }
      />
      <Route
        path='*'
        element={
          <NotFound />
        }
      />
    </Routes>
  )
}

export default ApplicationRoutes
