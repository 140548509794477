import React from 'react'
import { useStyles } from './style'
import { Typography, Paper } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

const DashboardTakeaway = ({ score, text, type, idx }) => {
  const classes = useStyles()

  const PositiveIcon = () => (
    <ArrowUpwardIcon className={classes.scorePositive} />
  )

  const NegativeIcon = () => (
    <ArrowDownwardIcon className={classes.scoreNegative} />
  )

  return (
    <Paper className={classes.root} data-test={`card-takeaway-${idx}`}>
      <Typography className={classes.text} variant='body1'>
        {type !== 'maturity' ? 'You are' : 'You need'}
      </Typography>
      <span className={classes.scoreBox}>
        <Typography className={classes.score} variant='h2'>
          {Math.abs(score)}
        </Typography>
        {
          type !== 'maturity' &&
            (score >= 0 ? <PositiveIcon /> : <NegativeIcon />)
        }
      </span>
      {
        type !== 'maturity'
          ? (
            <Typography className={classes.text} variant='body1'>
              {score > 0 ? 'points above' : 'points below'} {text}
            </Typography>
            )
          : (
            <Typography className={classes.text} variant='body1'>
              {text}
            </Typography>
            )
      }
    </Paper>
  )
}

export default DashboardTakeaway
