import { configureStore } from '@reduxjs/toolkit'
import register from './register'
import login from './login'
import dashboard from './dashboard'
import survey from './survey'
import user from './user'

export default configureStore({
  reducer: {
    [register.name]: register.reducer,
    [login.name]: login.reducer,
    [dashboard.name]: dashboard.reducer,
    [survey.name]: survey.reducer,
    [user.name]: user.reducer
  }
})
