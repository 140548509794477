import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useStyles } from './style'
import { useLocation, useNavigate } from 'react-router-dom'
import Auth from '@aws-amplify/auth'
import { loginSlice } from '../../reducers'
import { datadogRum } from '@datadog/browser-rum'
const VerifyPage = () => {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const search = new URLSearchParams(useLocation().search)
  const navigate = useNavigate()

  const username = search.get('username') || ''
  const code = search.get('code') || ''

  const confirmUser = async () => {
    try {
      await Auth.confirmSignUp(username, code)
      dispatcher(loginSlice.actions.setShowVerifyAlert(true))
      navigate('/', { replace: true })
    } catch (err) {
      console.log('Verify error:', err)
      console.log(`Error with username ${username} and code ${code}`)
      datadogRum.addError(err)
      dispatcher(loginSlice.actions.setShowVerifyError({ show: true, error: err }))
      navigate('/login')
    }
  }

  useEffect(() => {
    confirmUser()
  })

  return (
    <div className={classes.root}>
      <p>Verifying</p>
    </div>
  )
}

export default VerifyPage
